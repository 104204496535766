import React from 'react';
import { TrendEnum } from '../../../../constants/budgetPlanned';
import { Typography, TypographyProps } from '../../../../stories/atoms';
import { Direction } from '../../../../theme';
import { calculateColor, calculateIndexValue, calculateTrend } from '../../../../utils/budgetPlanned';
import { StyledTypography, StyledWrapper } from './StyledTrendIcon.styles';

type StyledIconProps = {
  originalAmount: number;
  previousAmount: number;
  textOptions?: TypographyProps;
  direction?: Direction;
  fullWidth?: boolean;
  hasPercentage?: boolean;
  valueFormatter?: (value: number) => string;
  calculateCustomTrend?: (originalAmount: number, previousAmount: number) => TrendEnum;
};

export const StyledTrendIconComponent: React.FC<StyledIconProps> = ({
  originalAmount,
  previousAmount,
  textOptions,
  direction = 'vertical',
  fullWidth = false,
  hasPercentage = false,
  valueFormatter,
  calculateCustomTrend,
}) => {
  const indexValue = calculateIndexValue(originalAmount, previousAmount);

  const trend = calculateCustomTrend
    ? calculateCustomTrend(originalAmount, previousAmount)
    : calculateTrend(originalAmount, previousAmount, false);

  const color = calculateColor(trend);

  return (
    <StyledWrapper direction={direction} fullWidth={fullWidth} data-testid="trend-wrapper">
      <StyledTypography
        margin={direction === 'horizontal' ? [0, 1, 0, 0] : undefined}
        type="button"
        {...textOptions}
      >
        {valueFormatter ? valueFormatter(previousAmount) : previousAmount}
        {hasPercentage ? '%' : ''}
      </StyledTypography>
      <Typography data-testid="trend-text-color" color={color}>{`(${indexValue})`}</Typography>
    </StyledWrapper>
  );
};
