import styled from 'styled-components';
import { Icon } from '../../../../stories/atoms';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${({ theme: { spacing } }) => spacing(5)};
`;

export const HeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LastUpdatedDateContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${({ theme: { spacing } }) => spacing(0, 0, 1, 0)};
`;

export const CommonStateContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContentContainer = styled.div`
  min-height: 0px;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-top: 1px solid
    ${({ theme: { color, palette } }) => (palette.mode === 'dark' ? color.borderGrayDark : color.borderGray)};
`;

export const StyledIcon = styled(Icon)`
  width: ${({ theme: { spacing } }) => spacing(8)};
  height: ${({ theme: { spacing } }) => spacing(8)};
  padding: ${({ theme: { spacing } }) => spacing(2)};
  border-radius: ${({ theme: { spacing } }) => spacing(2)};
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.backgroundSelectedHover : color.borderGray};
  justify-content: center;
  cursor: pointer;
`;

export const HeaderSideContentContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;
