import styled from 'styled-components';

export const StyledView = styled.div<{ isMobile: boolean }>`
  height: 100%;
  padding: ${({ isMobile, theme: { spacing } }) => (isMobile ? spacing(1, 0, 0) : spacing(2, 4))};
  display: flex;
  flex-direction: column;
`;

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
`;
