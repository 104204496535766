import { ToggleButton as MuiToggleButton } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import articlePlaceholder from '../../../../../assets/articlePlaceholder.png';
import { StorePerformanceInterval, createStorePerformanceRequest } from '../../../../../constants';
import { useEventContext } from '../../../../../global-state/eventContext';
import { useThemeContext } from '../../../../../global-state/themeContext';
import { TopSellingGarmentItem, useAsyncAction, useSoldProducts, useStoreInformation } from '../../../../../hooks';
import { Container, Icon, Icons, Typography } from '../../../../../stories/atoms';
import { DynamicTab, TabContentProps, TabLabelProps } from '../../../../../stories/molecules';
import { ToggleButton } from '../../../../../stories/molecules/toggleButton/ToggleButton';
import { homeTranslations } from '../../../../../translations';
import { isFailureResponse } from '../../../../../utils';
import { getArticleAssetUrl } from '../../../../../utils/soldProducts';
import { ProductSlider } from '../../../common/productInformationSlider/ProductSlider';

import { Table } from '../../../../../stories/molecules/tablev2/Table';
import { WidgetCardContent } from '../../../common/widgetCardContent/WidgetCardContent';
import { StyledArticles, StyledItem, StyledView } from './TopSellingGarmentWidget.styles';

export type TopSellingGarmentWidgetProps = {
  currentDate: Date;
  isNonSigninUser?: boolean;
  interval: StorePerformanceInterval;
  fromDate?: Date;
  toDate?: Date;
};

export const TopSellingGarmentWidget: FC<TopSellingGarmentWidgetProps> = observer(
  ({ currentDate, interval, fromDate, toDate }) => {
    const { fetchTopSellingGarmentData } = useSoldProducts();
    const { transactionEventsCount } = useEventContext();
    const { currencyCode: storeCurrency } = useStoreInformation();

    const [tabIndex, setTabIndex] = useState(0);
    const [lastUpdated, setLastUpdated] = useState<Date | null>(null);
    const [selectedGarment, setSelectedGarment] = useState<TopSellingGarmentItem | null>(null);

    const {
      result: topSellingData,
      isLoading: topSellingLoading,
      isError: topSellingError,
      triggerAction: triggerFetchTopSelling,
    } = useAsyncAction<TopSellingGarmentItem[]>();

    const [view, setView] = useState('view1');

    const { mode } = useThemeContext();

    const { t } = useTranslation();
    const { topSelling, common } = homeTranslations;

    const fetchTopSellingData = async () => {
      const request = createStorePerformanceRequest({
        interval,
        ...(interval === StorePerformanceInterval.DAILY ? { currentDate } : { fromDate, toDate }),
      });
      const response = await fetchTopSellingGarmentData(request);

      if (isFailureResponse(response)) {
        throw new Error();
      }
      setLastUpdated(response.data.lastUpdated);

      return response.data.topSellingGarments;
    };

    const handleChangeView = (event: React.MouseEvent<HTMLElement>, view2: string) => {
      if (view2 !== null) {
        setView(view2);
      }
    };

    const handleTabChange = (event: React.SyntheticEvent, newTabIndex: number) => {
      setTabIndex(newTabIndex);
    };

    const getGarmentsTableContent = (items: TopSellingGarmentItem[]) => {
      const headerItems = [
        { id: 'item', content: t(common.type.key, common.type.defaultValue), width: '70%' },
        { id: 'count', content: t(common.count.key, common.count.defaultValue), width: '30%' },
      ];

      return (
        <Table
          fixedHeight={'270px'}
          headerItems={headerItems}
          items={items}
          isLoading={topSellingLoading}
          getRowItems={(item) => {
            const imageUrl = getArticleAssetUrl({
              articleId: `${item.productId}${item.articleIdentifier}`,
              assetType: 'descriptiveStillLife',
              rendition: 'medium',
            });

            return [
              {
                id: '1',
                content: (
                  <StyledArticles>
                    <StyledItem
                      data-testid="article-image"
                      src={imageUrl}
                      alt=""
                      onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        target.src = articlePlaceholder;
                      }}
                      width="100"
                      height="100"
                    />
                    <div>
                      <Typography>{item.title}</Typography>
                      <Typography>
                        {item.whitePrice ? `${item.whitePrice} ${item.currency || storeCurrency || ''}` : '-'}
                      </Typography>
                    </div>
                  </StyledArticles>
                ),
              },
              {
                id: '2',
                content: (
                  <Typography color="textGray">
                    {view === 'view1'
                      ? item.soldPieces + ' pc'
                      : parseFloat(item.moneyGenerated).toFixed(1) + ' ' + storeCurrency}
                  </Typography>
                ),
              },
            ];
          }}
          onRowSelect={(item) => {
            setSelectedGarment(item);
          }}
          getIsRowSelected={(item) => {
            return selectedGarment?.productId === item.productId;
          }}
          stickyHeader
          noBorder
          noHeader
          skeletonRowCount={4}
        />
      );
    };

    const getTabsData = (
      topSellingGarmentItems: TopSellingGarmentItem[] | null,
    ): { tabsLabels: TabLabelProps[]; tabsContents: TabContentProps[] } => {
      if (!topSellingGarmentItems) {
        return { tabsLabels: [], tabsContents: [] };
      }

      const categoryGroups = topSellingGarmentItems.reduce<Map<string, TopSellingGarmentItem[]>>((map, item) => {
        const { category } = item;
        const group = map.get(category) || [];
        group.push(item);
        map.set(category, group);
        return map;
      }, new Map());

      const tabsLabels: TabLabelProps[] = [
        { tabName: t(common.allTabName.key, common.allTabName.defaultValue), isTabVisible: true },
      ];
      categoryGroups.forEach((_, key) => {
        tabsLabels.push({ tabName: key, isTabVisible: true });
      });

      const tabsContents: TabContentProps[] = [
        {
          tabContent: getGarmentsTableContent(topSellingGarmentItems.sort((a, b) => b.soldPieces - a.soldPieces)),
          visible: true,
        },
      ];

      categoryGroups.forEach((value) => {
        const sortedGarments = [...value].sort((a, b) => b.soldPieces - a.soldPieces);

        tabsContents.push({ tabContent: getGarmentsTableContent(sortedGarments), visible: true });
      });

      return { tabsLabels, tabsContents };
    };

    const fetchWidgetData = () => {
      setSelectedGarment(null);
      triggerFetchTopSelling(fetchTopSellingData);
    };

    useEffect(() => {
      setTabIndex(0);
      fetchWidgetData();
    }, [currentDate, interval, transactionEventsCount]);

    const isNodata = !!topSellingData && topSellingData.length === 0;

    const { tabsLabels, tabsContents } = getTabsData(topSellingData);

    return (
      <ProductSlider
        isOpen={!!selectedGarment}
        onClose={() => {
          setSelectedGarment(null);
        }}
        productDetails={selectedGarment}
        currencyCode={selectedGarment?.currency || storeCurrency || ''}
      >
        <WidgetCardContent
          headerIcon={Icons.CUSTOMERVALUE}
          headerText={t(topSelling.title.key, topSelling.title.defaultValue)}
          subHeaderText={t(topSelling.subTitle.key, topSelling.subTitle.defaultValue)}
          toolTipText={t(topSelling.toolTip.key, topSelling.toolTip.defaultValue)}
          lastUpdatedDate={lastUpdated}
          isLoading={topSellingLoading}
          isError={topSellingError}
          errorText={t(topSelling.error.key, topSelling.error.defaultValue)}
          errorRetryHandler={fetchWidgetData}
          isNoData={isNodata}
          headerSideContent={
            <ToggleButton
              value={view}
              onChange={handleChangeView}
              leftButton={
                <MuiToggleButton value="view1" aria-label="left button">
                  <Icon data-testid="left-icon" icon={Icons.DELIVERY} size="small" />
                </MuiToggleButton>
              }
              rightButton={Icons.DOLLAR}
            />
          }
          content={
            <>
              <Container wrap="nowrap" data-testid="top-selling-garment-overview" height="100%">
                <StyledView wrap="nowrap" data-testid="top-selling-garment-table" hasBorder>
                  <DynamicTab
                    tabLabelArray={tabsLabels}
                    handleTabChange={handleTabChange}
                    tabValue={tabIndex}
                    variant="scrollable"
                    tabBgColor={mode === 'light' ? 'baseGray' : 'backgroundTableHeaderDark'}
                    content={tabsContents}
                  />
                </StyledView>
              </Container>
            </>
          }
        />
      </ProductSlider>
    );
  },
);
