import axios, { AxiosRequestConfig } from 'axios';

export enum ErrorType {
  GENERAL_ERROR_BOUNDARY = 'GENERAL_ERROR_BOUNDARY',
  WIDGET_ERROR_BOUNDARY = 'WIDGET_ERROR_BOUNDARY',
  PAGE_ERROR_BOUNDARY = 'PAGE_ERROR_BOUNDARY',
  AUTH_ERROR = 'AUTH_ERROR',
}

type ErrorPayloadProps = {
  error: Error;
  errorType: ErrorType;
  info: Record<string, string | number | boolean | object | undefined>;
};

type SplunkEvent = {
  message: string;
  level: 'info' | 'warn' | 'error' | 'debug';
  [key: string]: string | number | boolean | object | undefined;
};

type SplunkPayload = {
  event: SplunkEvent;
  index: string;
  sourcetype: string;
};

const HEC_TOKEN = process.env.REACT_APP_SPLUNK_HEC_TOKEN;
const SPLUNK_URL = process.env.REACT_APP_SPLUNK_URL as string;
const INDEX = process.env.REACT_APP_SPLUNK_INDEX as string;

export const logError = async ({ error, errorType, info }: ErrorPayloadProps) => {
  await logToSplunk(`Error: ${error.message}`, info, 'error', errorType);
};

export const logInfo = async (
  message: string,
  additionalInfo: Record<string, string | number | boolean | object | undefined> = {},
) => {
  await logToSplunk(message, additionalInfo, 'info');
};

const logToSplunk = async (
  message: string,
  additionalInfo: Record<string, string | number | boolean | object | undefined> = {},
  level: SplunkEvent['level'] = 'error',
  errorType?: ErrorType,
) => {
  try {
    const payload: SplunkPayload = {
      event: {
        message,
        level,
        errorType,
        ...additionalInfo,
      },
      index: INDEX,
      sourcetype: '_json',
    };

    // Skip actual HTTP request in development
    if (process.env.NODE_ENV === 'development') {
      console.log('Logging to Splunk (simulated):', JSON.stringify(payload, null, 2));
      return;
    }

    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Splunk ${HEC_TOKEN}`,
        'Content-Type': 'application/json',
      },
    };

    await axios.post(SPLUNK_URL, payload, config);
  } catch (error) {
    console.error('Failed to log to Splunk:', error);
  }
};
