import styled from 'styled-components';

export const StyledTrendContainer = styled.div<{ isLeftElement?: boolean }>`
  display: flex;
  align-items: center;
  margin: ${({ theme: { spacing }, isLeftElement }) => isLeftElement && spacing(0, 2, 0, 0)};
  padding: ${({ theme: { spacing } }) => spacing(1, 0)};
  justify-content: center;
  align-items: center;
`;
