import { TrendEnum } from '../constants/budgetPlanned';
import { BudgetPlannedData } from '../hooks';

export const calculateDifference = (currentValue: number, comparedValue: number) => {
  return currentValue - comparedValue;
};

export const calculatePercentageDiffernce = (currentValue: number | null, comparedValue: number | null) => {
  if (!comparedValue || !currentValue || comparedValue === 0 || currentValue === 0) return 0;
  const difference = calculateDifference(currentValue, comparedValue) / comparedValue;
  return difference * 100;
};

export const calculateIndexValue = (currentValue: number | null, comparedValue: number | null) => {
  const index = 100 + calculatePercentageDiffernce(currentValue, comparedValue);
  return Math.round(index);
};

export const calculateTrend = (
  currentValue: number,
  comparedValue: number,
  isEffectiveHours: boolean,
): TrendEnum => {
  return isEffectiveHours
    ? currentValue > comparedValue
      ? TrendEnum.DOWN
      : TrendEnum.UP
    : currentValue < comparedValue
    ? TrendEnum.DOWN
    : TrendEnum.UP;
};

export const calculateColor = (trend: TrendEnum) => {
  return trend === TrendEnum.UP ? 'validationGreen' : 'red';
};

export const computeBudgetPlannedData = (
  today: number | null | undefined,
  budgetPlannedData: BudgetPlannedData | null,
  isEffectiveHours: boolean,
) => {
  const todayValue = today ? today : 0;
  const budgetValue = budgetPlannedData ? budgetPlannedData.budget : 0;
  const plannedValue = budgetPlannedData ? budgetPlannedData.planned : 0;

  const budgetDiff = calculateDifference(todayValue, budgetValue);
  const plannedDiff = calculateDifference(todayValue, plannedValue);

  const budgetPercentage = calculatePercentageDiffernce(todayValue, budgetValue);
  const plannedPercentage = calculatePercentageDiffernce(todayValue, plannedValue);

  const budgetIndexValue = calculateIndexValue(todayValue, budgetValue);
  const plannedIndexValue = calculateIndexValue(todayValue, plannedValue);

  const budgetTrend = calculateTrend(todayValue, budgetValue, isEffectiveHours);
  const plannedTrend = calculateTrend(todayValue, plannedValue, isEffectiveHours);

  return {
    todayValue,
    budgetValue,
    plannedValue,
    budgetDiff,
    plannedDiff,
    budgetPercentage,
    plannedPercentage,
    budgetIndexValue,
    plannedIndexValue,
    budgetTrend,
    plannedTrend,
  };
};
