// TODO: Remove this when fixing the unit tests - BTCOBUI-2597
/* istanbul ignore file */

import { differenceInSeconds } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icons, SkeletonLoader } from '../../../stories/atoms';
import { IconWithText } from '../../../stories/molecules';
import { commonTranslations } from '../../../translations';
import { GoogleMapView } from './GoogleMapView';
import { ButtonsContainer, Container, MapContainer } from './Map.styles';
import { TencentMapView } from './TencentMapView';

type ProviderButtonProps = {
  icon: Icons;
  label: string;
  onClick: () => void;
};

const ProviderButton = ({ icon, label, onClick }: ProviderButtonProps) => {
  return (
    <IconWithText
      label={label}
      backgroundColor="white"
      padding={[1, 1, 1, 1]}
      iconOptions={{ icon, size: 'small' }}
      containerStyles={{ borderRadius: 2, boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px' }}
      onClick={onClick}
    />
  );
};

type MapProvider = 'Google' | 'Tencent';

export type CenterCoordinates = {
  latitude: number;
  longitude: number;
};

export type MarkerDetails = {
  id: string;
  latitude: number;
  longitude: number;
};

type MapProps = {
  prefferedProvider?: MapProvider;
  zoom: number;
  center?: CenterCoordinates;
  selectedMarkerId: string;
  markers: MarkerDetails[];
  panOnMarkerSelect?: boolean;
  onMarkerSelectZoom?: number;
  shouldFitMarkers?: boolean;
  onMarkerClick: (markerId: string) => void;
  getMarkerIconUrl: (markerId?: string, forceIsActive?: boolean) => string | undefined;
};

export const Map = ({
  prefferedProvider = 'Google',
  zoom,
  center,
  selectedMarkerId,
  markers,
  panOnMarkerSelect = false,
  onMarkerSelectZoom = 12,
  shouldFitMarkers = true,
  onMarkerClick,
  getMarkerIconUrl,
}: MapProps) => {
  const { t } = useTranslation();
  const initDateTimeRef = useRef(new Date());

  const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false);
  const [isTencentMapsLoaded, setIsTencentMapsLoaded] = useState(false);

  const [selectedMapProvider, setSelectedMapProvider] = useState<MapProvider>(prefferedProvider);

  useEffect(() => {
    const initDateTime = initDateTimeRef.current;

    if (differenceInSeconds(initDateTime, new Date()) < 5) {
      return;
    }

    if (isGoogleMapsLoaded && !isTencentMapsLoaded) {
      setSelectedMapProvider('Google');
    }

    if (isTencentMapsLoaded && !isGoogleMapsLoaded) {
      setSelectedMapProvider('Tencent');
    }
  }, [isGoogleMapsLoaded, isTencentMapsLoaded]);

  return (
    <Container>
      {!isGoogleMapsLoaded && !isTencentMapsLoaded && <SkeletonLoader variant="rect" height="100%" width="100%" />}

      {isGoogleMapsLoaded && isTencentMapsLoaded && (
        <ButtonsContainer>
          <ProviderButton
            icon={Icons.GOOGLE}
            label={t(commonTranslations.map.google.key, commonTranslations.map.google.defaultValue)}
            onClick={() => setSelectedMapProvider('Google')}
          />

          <ProviderButton
            icon={Icons.TENCENT_MAPS}
            label={t(commonTranslations.map.tencent.key, commonTranslations.map.tencent.defaultValue)}
            onClick={() => setSelectedMapProvider('Tencent')}
          />
        </ButtonsContainer>
      )}

      <MapContainer isVisible={selectedMapProvider === 'Google' && isGoogleMapsLoaded}>
        <GoogleMapView
          zoom={zoom}
          center={center}
          shouldFitMarkers={shouldFitMarkers}
          selectedMarkerId={selectedMarkerId}
          markers={markers}
          panOnMarkerSelect={panOnMarkerSelect}
          onMarkerSelectZoom={onMarkerSelectZoom}
          onLoaded={() => setIsGoogleMapsLoaded(true)}
          onMarkerClick={onMarkerClick}
          getMarkerIconUrl={getMarkerIconUrl}
        />
      </MapContainer>

      <MapContainer isVisible={selectedMapProvider === 'Tencent' && isTencentMapsLoaded}>
        <TencentMapView
          zoom={zoom}
          center={center}
          selectedMarkerId={selectedMarkerId}
          markers={markers}
          panOnMarkerSelect={panOnMarkerSelect}
          onMarkerSelectZoom={onMarkerSelectZoom}
          onLoaded={() => setIsTencentMapsLoaded(true)}
          onMarkerClick={onMarkerClick}
          getMarkerIconUrl={getMarkerIconUrl}
        />
      </MapContainer>
    </Container>
  );
};
