import styled from 'styled-components';
import { Icon, Typography } from '../../../../../stories/atoms';

export const FlexRow = styled.div`
  display: flex;
`;

export const FlexColumn = styled(FlexRow)`
  flex-direction: column;
`;

export const StyledWrapper = styled(FlexRow)`
  align-items: center;
`;

export const StyledEfficiencyWrapper = styled(FlexRow)<{ isMobileView: boolean }>`
  flex: 1;
  flex-direction: ${({ isMobileView }) => (isMobileView ? 'column' : 'row')};
  padding: ${({ theme: { spacing } }) => spacing(4)};
  gap: ${({ theme: { spacing } }) => spacing(2)};
  justify-content: space-between;
`;

export const StyledSection = styled(FlexColumn)`
  flex: 1;
  padding: ${({ theme: { spacing } }) => spacing(2)};
  border: 1px solid
    ${({ theme: { color, palette } }) =>
      palette.mode === 'dark' ? color.backgroundSelectedHover : color.borderGray};
  border-radius: ${({ theme: { spacing } }) => spacing(2)};
`;

export const SectionHeader = styled(FlexRow)`
  align-items: center;
`;

export const StyledTypography = styled(Typography)`
  flex: 1;
  align-content: space-around;
`;

export const MetricSection = styled(FlexRow)<{ isMobileView: boolean }>`
  flex-direction: ${({ isMobileView }) => (isMobileView ? 'row' : 'column')};
  justify-content: space-between;
`;

export const StyledIcon = styled(Icon)`
  width: ${({ theme: { spacing } }) => spacing(6)};
  height: ${({ theme: { spacing } }) => spacing(6)};
  padding: ${({ theme: { spacing } }) => spacing(1)};
  margin: ${({ theme: { spacing } }) => spacing(0, 1, 0, 0)};
`;
