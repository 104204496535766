export const salesAnalysisTranslation = {
  subHeader: {
    key: 'salesAnalysisTranslation.subHeader',
    defaultValue: 'Sales Analysis per hour on ',
  },
  stores: {
    key: 'salesAnalysisTranslation.stores',
    defaultValue: ' stores',
  },
  storeID: {
    key: 'salesAnalysisTranslation.storeID',
    defaultValue: 'Store ID',
  },
  total: {
    key: 'salesAnalysisTranslation.total',
    defaultValue: 'Total',
  },
  market: {
    key: 'salesAnalysisTranslation.market',
    defaultValue: 'Market',
  },
  error: {
    errorTitle: {
      key: 'salesAnalysisTranslation.error.errorTitle',
      defaultValue: 'Something went wrong!',
    },
    errorSubtitle: {
      key: 'salesAnalysisTranslation.error.errorSubtitle',
      defaultValue: 'There was an error while fetching sales analysis data. Please try again later.',
    },
  },
  noData: {
    title: {
      key: 'salesAnalysisTranslation.noData.title',
      defaultValue: 'Uh oh!!',
    },
    subtitle: {
      key: 'salesAnalysisTranslation.noData.subtitle',
      defaultValue: 'No Sales Data at the moment',
    },
  },
};
