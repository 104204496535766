import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import articlePlaceholder from '../../../assets/articlePlaceholder.png';
import { SortDirection } from '../../../global-state/types';
import { SoldProductItem } from '../../../hooks';
import { Typography } from '../../../stories/atoms';
import { HeaderRowItem, Table } from '../../../stories/molecules/tablev2/Table';
import { muiTheme } from '../../../theme';
import { soldProductsTranslations } from '../../../translations';
import { getArticleAssetUrl } from '../../../utils/soldProducts';
import { BadgeWrapper, StyledItem } from './ProductTable.styles';

enum ProductColumnType {
  Photo = 'photo',
  Season = 'season',
  DepartmentNumber = 'departmentNumber',
  ProductId = 'productId',
  Price = 'price',
  SalesNet = 'salesNet',
  SoldPieces = 'soldPieces',
}

type ProductTableProps = {
  isLoading: boolean;
  items: SoldProductItem[];
  selectedItemProductId: string | null;
  onItemSelect: (item: SoldProductItem | null) => void;
};

export const ProductTable = ({ isLoading, items, selectedItemProductId, onItemSelect }: ProductTableProps) => {
  const { t } = useTranslation();
  const isMobileView = useMediaQuery(muiTheme.breakpoints.down('desktop'));

  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const rowsPerPageOptions = [100, 250, 500];
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [sortColumnId, setsortColumnId] = useState<ProductColumnType | null>(null);
  const [sortDirection, setSortDirection] = useState<SortDirection | null>(null);

  const onColumnSort = (columnType: ProductColumnType) => {
    if (columnType !== sortColumnId) {
      setsortColumnId(columnType);
      setSortDirection(SortDirection.Descending);
      return;
    }

    if (sortDirection === SortDirection.Ascending) {
      setsortColumnId(columnType);
      setSortDirection(SortDirection.Descending);
      return;
    }

    setsortColumnId(columnType);
    setSortDirection(SortDirection.Ascending);
  };

  const headerItems: HeaderRowItem<ProductColumnType>[] = [
    {
      id: ProductColumnType.Photo,
      width: '10%',
      content: (
        <Typography whiteSpace="nowrap">
          {t(soldProductsTranslations.image.key, soldProductsTranslations.image.defaultValue)}
        </Typography>
      ),
    },
    {
      id: ProductColumnType.Season,
      width: '10%',
      content: (
        <Typography whiteSpace="nowrap">
          {t(soldProductsTranslations.season.key, soldProductsTranslations.season.defaultValue)}
        </Typography>
      ),
    },
    {
      id: ProductColumnType.DepartmentNumber,
      width: '12.5%',
      content: (
        <Typography whiteSpace="nowrap">
          {`${t(
            soldProductsTranslations.departmentId.key,
            soldProductsTranslations.departmentId.defaultValue,
          )}/${t(soldProductsTranslations.name.key, soldProductsTranslations.name.defaultValue)}`}
        </Typography>
      ),
    },
    {
      id: ProductColumnType.ProductId,
      width: '15%',
      content: (
        <Typography whiteSpace="nowrap">{`${t(
          soldProductsTranslations.productId.key,
          soldProductsTranslations.productId.defaultValue,
        )}/${t(soldProductsTranslations.name.key, soldProductsTranslations.name.defaultValue)}`}</Typography>
      ),
    },
    // {
    //   id: 'piecesRemaining',
    //   width: '12.5%',
    //   content: <Typography>{t(soldProductsTranslations.piecesRemaining.key, soldProductsTranslations.piecesRemaining.defaultValue)}</Typography>,
    // },
    {
      id: ProductColumnType.Price,
      width: '10%',
      content: (
        <Typography whiteSpace="nowrap">
          {t(soldProductsTranslations.price.key, soldProductsTranslations.price.defaultValue)}
        </Typography>
      ),
    },
    {
      id: ProductColumnType.SalesNet,
      width: '10%',
      content: (
        <Typography whiteSpace="nowrap">
          {t(soldProductsTranslations.salesNet.key, soldProductsTranslations.salesNet.defaultValue)}
        </Typography>
      ),
    },
    {
      id: ProductColumnType.SoldPieces,
      width: '10%',
      content: (
        <Typography whiteSpace="nowrap">
          {t(soldProductsTranslations.piecesSold.key, soldProductsTranslations.piecesSold.defaultValue)}
        </Typography>
      ),
    },
    // {
    //   id: 'speed',
    //   width: '10%',
    //   content: <Typography></Typography>,
    // },
  ];

  useEffect(() => {
    if (isLoading) {
      setCurrentPageIndex(0);
    }
  }, [isLoading]);

  useEffect(() => {
    onItemSelect(null);
  }, [currentPageIndex, currentRowsPerPage, sortColumnId, sortDirection]);

  return (
    <Table
      stickyHeader
      useZebraStyles
      isLoading={isLoading}
      rowsPerPage={currentRowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      items={items || []}
      currentPageIndex={currentPageIndex}
      sortConfig={{
        price: {
          getItemValue: (item) => item.price,
        },
        salesNet: {
          getItemValue: (item) => item.salesNet,
        },
        soldPieces: {
          getItemValue: (item) => item.soldPieces,
        },
      }}
      sortColumnId={sortColumnId || undefined}
      sortDirection={sortDirection || undefined}
      getIsRowSelected={(item) => item.productId === selectedItemProductId}
      onPageIndexChange={(pageIndex) => setCurrentPageIndex(pageIndex)}
      onRowSelect={(item) => onItemSelect(item)}
      onRowsPerPageChange={(val) => setCurrentRowsPerPage(val)}
      onSort={onColumnSort}
      headerItems={headerItems}
      getRowItems={(item) => {
        const imageUrl = getArticleAssetUrl({
          articleId: `${item.productId}${item.mostSoldArticle}`,
          assetType: 'descriptiveStillLife',
          rendition: 'medium',
        });

        return [
          {
            id: ProductColumnType.Photo,
            content: (
              <StyledItem
                data-testid="article-image"
                src={imageUrl}
                alt={item.productName}
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.src = articlePlaceholder;
                }}
                width="100"
                height="100"
              />
            ),
          },
          {
            id: ProductColumnType.Season,
            content: <Typography>{item.season}</Typography>,
          },
          {
            id: ProductColumnType.DepartmentNumber,
            content: (
              <>
                <Typography type="body3">{item.departmentNumber}</Typography>
                <Typography type="legal" color="textGray" padding={[1, 0, 0, 0]}>
                  {item.departmentName}
                </Typography>
              </>
            ),
          },
          {
            id: ProductColumnType.ProductId,
            content: (
              <>
                <BadgeWrapper isMobileView={isMobileView}>
                  <Typography type="body3" margin={[0, 2, 0, 0]}>
                    {item.productId}
                  </Typography>
                  {/* TODO: add when BE spec is updated */}
                  {/* <Badge backgroundColor={stockAvailable ? 'successLight' : 'errorLight'}>
              <Typography type="body3" color={stockAvailable ? 'validationGreen' : 'red'}>
                {stockAvailable
                  ? t(soldProductsTranslations.inStock.key, soldProductsTranslations.inStock.defaultValue)
                  : t(soldProductsTranslations.outOfStock.key, soldProductsTranslations.outOfStock.defaultValue)}
              </Typography>
            </Badge> */}
                </BadgeWrapper>
                <Typography type="legal" color="textGray" padding={[1, 0, 0, 0]}>
                  {item.productName}
                </Typography>
              </>
            ),
          },
          {
            id: ProductColumnType.Price,
            content: <Typography>{item.price}</Typography>,
          },
          {
            id: ProductColumnType.SalesNet,
            content: <Typography>{`${item.salesNet.toFixed(2)}`}</Typography>,
          },

          {
            id: ProductColumnType.SoldPieces,
            content: (
              <Typography>{`${item.soldPieces} ${t(
                soldProductsTranslations.pieces.key,
                soldProductsTranslations.pieces.defaultValue,
              )}`}</Typography>
            ),
          },
        ];
      }}
    />
  );
};
