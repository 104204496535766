import { authorizationEndpoint, blacklistPermissions, permissionsEndpoint } from '../constants';
import { useAuthAxiosRequest } from './useAuthAxiosRequest';
import { useUser } from './useController';

export const permissionTypes = [
  'transactionSearchPage',
  'employeesAndRolesPage',
  'avatarManagementPage',
  'kpiTargetsPage',
  'chatBoxPage',
  'profitAndLossPage',
  'salesAnalysisPage',
  'shareOfReceiptWidget',
  'topSellingGarmentsWidget',
  'salesPerformanceWidget',
  'conversionRateWidget',
  'registeredReceiptsWidget',
  'tenderOverviewWidget',
  'assetManagementWidget',
  'avgPiecesPerReceiptWidget',
  'avgPurchasePerReceiptWidget',
  'effectiveHoursWidget',
  'personalSettings',
  'kpiTargets',
  'areaCaps',
  'lockTargets',
  'highlightWidget',
  'financialPlanned',
  'financialBudget',
  'newMembersWidget',
  'appCustomers',
  'employeePermissions',
  'areaComparisonWidget',
  'soldProductsPage',
  'productIndexSubindex',
  'customerFeedBackWidget',
  'blacklistPermissionsForArea',
  'queueLengthWidget',
  'scoShareOfReceipt',
  'scoSalesPerformance',
  'orisCustomerWidget',
  'rfidApprovalsWidget',
  'rfidSalesWidget',
  'efficiencyWidget',
] as const;

export type UserPermissionType = (typeof permissionTypes)[number];

export type PermissionItemsMap = {
  [key in UserPermissionType]: string;
};

export type GetBlacklistPermissionsResponse = {
  areaCode: string;
  permissions: UserPermissionType[];
};

export type PostBlacklistPermissionsRequest = {
  permissions: UserPermissionType[];
};

export const useUserPermissions = () => {
  const {
    get: { currentStoreId },
  } = useUser();

  const { authAxiosRequest } = useAuthAxiosRequest();

  const fetchUserPermissions = async (upn: string, storeId: string) => {
    const roleMatrixResponse = await authAxiosRequest<PermissionItemsMap>(
      `${authorizationEndpoint}${permissionsEndpoint}/${upn}`,
      {
        params: { storeId },
      },
    );

    return roleMatrixResponse;
  };

  const getBlacklistPermissions = async () => {
    const response = await authAxiosRequest<GetBlacklistPermissionsResponse>(
      `${authorizationEndpoint}${blacklistPermissions}/${currentStoreId}`,
    );

    return response;
  };

  const postBlacklistPermissions = async (request: PostBlacklistPermissionsRequest) => {
    const roleMatrixResponse = await authAxiosRequest<GetBlacklistPermissionsResponse>(
      `${authorizationEndpoint}${blacklistPermissions}/${currentStoreId}`,
      {
        method: 'POST',
        data: request,
      },
    );

    return roleMatrixResponse;
  };

  return { fetchUserPermissions, getBlacklistPermissions, postBlacklistPermissions };
};
