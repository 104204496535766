import { format, intlFormat, startOfToday } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StorePerformanceInterval, createStorePerformanceRequest } from '../../constants';
import { StoreSalesData, useAsyncAction, useTransaction } from '../../hooks';
import i18n from '../../i18n';
import { Typography } from '../../stories/atoms';
import { Animation, Animations, DropdownMenu, DropdownMenuItem, FilterTag } from '../../stories/molecules';
import { DatePicker } from '../../stories/molecules/date-picker/DatePicker';
import { Page } from '../../stories/organisms';
import { salesAnalysisTranslation } from '../../translations/salesAnalysis';
import { MarketMapping, isFailureResponse } from '../../utils';
import { localeMap } from '../../utils/language';
import { FlexWrapper, HeaderWrapper, SubHeaderWrapper, TableWrapper } from './SalesAnalysisPage.styles';
import { SalesTable } from './salesTable/SalesTable';

export const SalesAnalysisPage: FC = observer(() => {
  const [currentDate, setCurrentDate] = useState<Date>(startOfToday());
  const [selectedMarkets, setSelectedMarkets] = useState<string[]>([]);

  const { t } = useTranslation();
  const { fetchSalesAnalysisData } = useTransaction();

  const {
    result: salesAnalysisData,
    isLoading: salesAnalysisLoading,
    isError: salesAnalysisError,
    triggerAction: triggerGetSalesAnalysis,
  } = useAsyncAction<StoreSalesData[]>();

  const fetchData = useCallback(async () => {
    const request = createStorePerformanceRequest({
      interval: StorePerformanceInterval.DAILY,
      currentDate,
    });

    const response = await fetchSalesAnalysisData(request);

    if (isFailureResponse(response)) {
      throw new Error();
    }

    return response.data;
  }, [currentDate]);

  useEffect(() => {
    triggerGetSalesAnalysis(fetchData);
  }, [currentDate]);

  const menuItems: DropdownMenuItem[] = Object.entries(
    salesAnalysisData?.reduce((acc, store) => {
      const marketKey = store.storeId.slice(0, 2);
      if (MarketMapping[marketKey]) {
        acc[marketKey] = MarketMapping[marketKey];
      }
      return acc;
    }, {} as Record<string, string>) || {},
  ).map(([key, item]) => ({
    key,
    item,
  }));

  const filteredData = salesAnalysisData?.filter((store) =>
    selectedMarkets.length ? selectedMarkets.includes(store.storeId.slice(0, 2)) : true,
  );
  const uniqueFilteredMarkets = [...(new Set(filteredData?.map((store) => store.storeId.slice(0, 2))) || [])];
  const isSingleMarket = uniqueFilteredMarkets.length === 1;
  const showTotalForMarket = isSingleMarket && filteredData && filteredData.length > 0;

  const isNoData =
    !salesAnalysisLoading &&
    !salesAnalysisError &&
    (!filteredData || filteredData.every((store) => store.hourlySales.every((sale) => sale.netSale === 0)));

  return (
    <Page
      header={
        <>
          <HeaderWrapper>
            <DatePicker
              inputSelectedDate={currentDate}
              label={format(currentDate, 'dd-MMM-yyyy', { locale: localeMap[i18n.language] })}
              onChange={(date) => setCurrentDate(date)}
              isDropdown
              disableFutureDates
            />
            <DropdownMenu
              dropdownLabel={t(salesAnalysisTranslation.market.key, salesAnalysisTranslation.market.defaultValue)}
              menuPosition="left"
              menuItems={menuItems}
              checkbox={true}
              multiSelect
              initialSelected={selectedMarkets}
              onSelect={(selectedItems) => {
                setSelectedMarkets(selectedItems.map((selected) => selected));
              }}
              buttonContentProps={{ isDisabled: isNoData }}
            />
          </HeaderWrapper>
        </>
      }
      body={
        <div data-testid="sales-analysis-page">
          <SubHeaderWrapper>
            <FlexWrapper>
              <Typography data-testid="sub-header" padding={[2, 0]}>
                {t(salesAnalysisTranslation.subHeader.key, salesAnalysisTranslation.subHeader.defaultValue)} : {''}
                {intlFormat(currentDate, {}, { locale: i18n.language })}
              </Typography>

              {selectedMarkets.length > 0 &&
                selectedMarkets.map((market) => (
                  <FilterTag
                    key={market}
                    title={MarketMapping[market]}
                    onClick={() =>
                      setSelectedMarkets(selectedMarkets.filter((selectedKey) => selectedKey !== market))
                    }
                  />
                ))}
            </FlexWrapper>
            {filteredData && (
              <Typography data-testid="stores-count">{`${filteredData.length} ${t(
                salesAnalysisTranslation.stores.key,
                salesAnalysisTranslation.stores.defaultValue,
              )}`}</Typography>
            )}
          </SubHeaderWrapper>

          {!salesAnalysisLoading && salesAnalysisError && (
            <Animation
              animation={Animations.SAD}
              title={t(
                salesAnalysisTranslation.error.errorTitle.key,
                salesAnalysisTranslation.error.errorTitle.defaultValue,
              )}
              subtitle={t(
                salesAnalysisTranslation.error.errorSubtitle.key,
                salesAnalysisTranslation.error.errorSubtitle.defaultValue,
              )}
            />
          )}

          {isNoData && (
            <Animation
              animation={Animations.CONFUSED}
              title={t(
                salesAnalysisTranslation.noData.title.key,
                salesAnalysisTranslation.noData.title.defaultValue,
              )}
              subtitle={t(
                salesAnalysisTranslation.noData.subtitle.key,
                salesAnalysisTranslation.noData.subtitle.defaultValue,
              )}
            />
          )}

          <TableWrapper>
            <SalesTable
              items={filteredData || []}
              isLoading={salesAnalysisLoading}
              showTotalForMarket={showTotalForMarket}
              isFilterSelected={selectedMarkets.length > 0}
              currentDate={currentDate}
            />
          </TableWrapper>
        </div>
      }
    />
  );
});
