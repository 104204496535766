import styled from 'styled-components';

export const StyledSubHeader = styled.div`
  display: flex;
  gap: ${({ theme: { spacing } }) => spacing(2)};
  align-items: center;
  align-items: flex-start;
  padding: ${({ theme: { spacing } }) => spacing(2, 4)};
`;

export const StyledBreakdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme: { spacing } }) => spacing(1)};
`;
export const StyledLegendContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const StyledView = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
