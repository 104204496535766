import { rest } from 'msw';
import {
  appCustomersEndpoint,
  averageNetSalePerReceiptEndpoint,
  averagePiecesPerReceipt,
  baseUrl,
  budgetPlannedEndpoint,
  conversionRateWidgetEndpoint,
  countEndpoint,
  customerFeedbackEndpoint,
  effectiveHoursEndpoint,
  hourlyTurnoverWidgetEndpoint,
  newMembersEndpoint,
  orisCustomersEndpoint,
  queueLengthEndpoint,
  receiptEndpoint,
  receiptSalesAnalysisEndpoint,
  registeredReceiptsWidgetEndpoint,
  rfidApprovalsEndpoint,
  rfidCountEndpoint,
  rfidEndpoint,
  rfidSalesRetaggingEndpoint,
  salesAnalysisEndpoint,
  scoEndpoint,
  shareOfReceiptsEndpoint,
  sptEndpoint,
  takt1Endpoint,
  tenderWidgetEndpoint,
  transactionEndpoint,
  TransactionFilterType,
} from '../../constants';
import {
  getAppCustomersData,
  getAveragePiecesPerReceiptData,
  getBudgetPlannedData,
  getConversionRateData,
  getCustomerFeedbackData,
  getEffectiveHours,
  getFetchRfidApprovalsDataMockResponse,
  getFetchRfidSalesRetaggingDataMockResponse,
  getHourlyTournoverData,
  getMockORISData,
  getMockReceiptData,
  getMockRfidCountData,
  getMockSCOShareOfReceiptsData,
  getMockShareOfReceiptsData,
  getMockStoreSalesData,
  getMockTransactionCountData,
  getMockTransactionData,
  getNewMembersData,
  getQueueLengthData,
  getReceiptSalesAnalysisData,
  getReceiptWidgetData,
  getSalesNetPerReceiptData,
  getSCOHourlyTournoverData,
  getTenderWidgetData,
} from '../mocks/transaction-search.mock';

export const transactionRequest = (addDelay?: boolean) =>
  rest.get(`${baseUrl}/transaction-search/:storeId${transactionEndpoint}`, (_req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(getMockTransactionData(100)),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const receiptRequest = (addDelay?: boolean) =>
  rest.get(`${baseUrl}/transaction-search/:storeId${receiptEndpoint}/:receiptNumber`, (_req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(getMockReceiptData()),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const transactionCountRequest = (addDelay = false, totalCount = 200) =>
  rest.get(`${baseUrl}/transaction-search/:storeId${countEndpoint}`, (req, res, ctx) => {
    const filterType = req.url.search.split('=')[3] as TransactionFilterType;

    return res(
      ctx.status(200),
      ctx.json(getMockTransactionCountData(filterType, totalCount)),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const fetchTenderWidgetData = (addDelay = false) =>
  rest.get(`${baseUrl}/transaction-search/:storeId${tenderWidgetEndpoint}`, (_, res, ctx) => {
    const mockData = getTenderWidgetData();

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const fetchReceiptWidgetData = (addDelay = false) =>
  rest.get(`${baseUrl}/transaction-search/:storeId${registeredReceiptsWidgetEndpoint}`, (_, res, ctx) => {
    const mockData = getReceiptWidgetData();

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const fetchHourlyTurnoverWidgetData = (addDelay = false) =>
  rest.get(`${baseUrl}/transaction-search/:storeId${hourlyTurnoverWidgetEndpoint}`, (_, res, ctx) => {
    const mockData = getHourlyTournoverData();

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const fetchSCOHourlyTurnoverWidgetData = (addDelay = false) =>
  rest.get(
    `${baseUrl}/transaction-search/:storeId${hourlyTurnoverWidgetEndpoint}${scoEndpoint}`,
    (_, res, ctx) => {
      const mockData = getSCOHourlyTournoverData();

      return res(
        ctx.status(200),
        ctx.json(mockData),
        ctx.set('Content-Type', 'application/json'),
        ctx.delay(addDelay ? 500 : 0),
      );
    },
  );

export const fetchNetSalesBudget = (addDelay = false) =>
  rest.get(
    `${baseUrl}/transaction-search/:storeId${hourlyTurnoverWidgetEndpoint}${budgetPlannedEndpoint}`,
    (_, res, ctx) => {
      const mockData = getBudgetPlannedData();

      return res(
        ctx.status(200),
        ctx.json(mockData),
        ctx.set('Content-Type', 'application/json'),
        ctx.delay(addDelay ? 500 : 0),
      );
    },
  );

export const fetchConversionRateWidgetData = (addDelay = false) =>
  rest.get(`${baseUrl}/transaction-search/:storeId${conversionRateWidgetEndpoint}`, (_, res, ctx) => {
    const mockData = getConversionRateData();

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const fetchShareOfReceipts = (addDelay = false) =>
  rest.get(`${baseUrl}/transaction-search/:storeId${shareOfReceiptsEndpoint}`, (_, res, ctx) => {
    const mockData = getMockShareOfReceiptsData();

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const fetchSCOShareOfReceiptsData = (addDelay = false) =>
  rest.get(`${baseUrl}/transaction-search/:storeId${shareOfReceiptsEndpoint}${scoEndpoint}`, (_, res, ctx) => {
    const mockData = getMockSCOShareOfReceiptsData();

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const fetchNewMembers = (addDelay = false) =>
  rest.get(`${baseUrl}/transaction-search/:storeId${newMembersEndpoint}`, (_, res, ctx) => {
    const mockData = getNewMembersData();

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const fetchAppCustomersData = (addDelay = false) =>
  rest.get(`${baseUrl}/transaction-search/:storeId${appCustomersEndpoint}`, (_, res, ctx) => {
    const mockData = getAppCustomersData();

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const fetchEffectiveHoursData = (addDelay = false) =>
  rest.get(`${baseUrl}/transaction-search/:storeId${effectiveHoursEndpoint}`, (_, res, ctx) => {
    const mockData = getEffectiveHours();

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const fetchEffectiveHoursBudget = (addDelay = false) =>
  rest.get(
    `${baseUrl}/transaction-search/:storeId${effectiveHoursEndpoint}${budgetPlannedEndpoint}`,
    (_, res, ctx) => {
      const mockData = getBudgetPlannedData();

      return res(
        ctx.status(200),
        ctx.json(mockData),
        ctx.set('Content-Type', 'application/json'),
        ctx.delay(addDelay ? 500 : 0),
      );
    },
  );

export const fetchSalesNetPerReceiptData = (addDelay = false) =>
  rest.get(`${baseUrl}/transaction-search/:storeId${averageNetSalePerReceiptEndpoint}`, (_, res, ctx) => {
    const mockData = getSalesNetPerReceiptData();

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const fetchReceiptSalesAnalysisData = (addDelay = false) =>
  rest.get(`${baseUrl}/transaction-search/:storeId${receiptSalesAnalysisEndpoint}`, (_, res, ctx) => {
    const mockData = getReceiptSalesAnalysisData();

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const fetchTakt1Budget = (addDelay = false) =>
  rest.get(`${baseUrl}/transaction-search/:storeId${takt1Endpoint}${budgetPlannedEndpoint}`, (_, res, ctx) => {
    const mockData = getBudgetPlannedData();

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const fetchSptBudget = (addDelay = false) =>
  rest.get(`${baseUrl}/transaction-search/:storeId${sptEndpoint}${budgetPlannedEndpoint}`, (_, res, ctx) => {
    const mockData = getBudgetPlannedData();

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const fetchAveragePiecesPerReceiptData = (addDelay = false) =>
  rest.get(`${baseUrl}/transaction-search/:storeId${averagePiecesPerReceipt}`, (_, res, ctx) => {
    const mockData = getAveragePiecesPerReceiptData();

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const fetchQueueLengthData = (addDelay = false) =>
  rest.get(`${baseUrl}/transaction-search/:storeId${queueLengthEndpoint}`, (_, res, ctx) => {
    const mockData = getQueueLengthData();

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const fetchCustomerFeedbackData = (addDelay = false) =>
  rest.get(`${baseUrl}/transaction-search/:storeId${customerFeedbackEndpoint}`, (_, res, ctx) => {
    const mockData = getCustomerFeedbackData();

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const mockRfidApprovalsData = (addDelay = false) =>
  rest.get(`${baseUrl}/transaction-search/:storeId${rfidEndpoint}${rfidApprovalsEndpoint}`, (_, res, ctx) => {
    const mockData = getFetchRfidApprovalsDataMockResponse();

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const mockRfidSalesRetaggingData = (addDelay = false) =>
  rest.get(`${baseUrl}/transaction-search/:storeId${rfidEndpoint}${rfidSalesRetaggingEndpoint}`, (_, res, ctx) => {
    const mockData = getFetchRfidSalesRetaggingDataMockResponse();

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const fetchRfidCountData = (addDelay = false) =>
  rest.get(`${baseUrl}/transaction-search/:storeId${rfidEndpoint}${rfidCountEndpoint}`, (_, res, ctx) => {
    const mockData = getMockRfidCountData();

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const fetchORISData = (addDelay = false) =>
  rest.get(`${baseUrl}/transaction-search/:storeId${orisCustomersEndpoint}`, (_, res, ctx) => {
    const mockData = getMockORISData();

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const fetchSalesAnalysisData = (addDelay = false) =>
  rest.get(`${baseUrl}/transaction-search/:storeId${salesAnalysisEndpoint}`, (_, res, ctx) => {
    const mockData = getMockStoreSalesData();

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });
