import { useLoadScript } from '@react-google-maps/api';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StoreDetails } from '../../../global-state/types';
import { useAsyncAction, useStores, useUser } from '../../../hooks';
import { Container, SkeletonLoader, Tooltip, Typography } from '../../../stories/atoms';
import LocationIcon from '../../../stories/atoms/icon/assets/Location.svg';
import LocationActiveIcon from '../../../stories/atoms/icon/assets/LocationActive.svg';
import LocationActiveHM from '../../../stories/atoms/icon/assets/LocationActive_HM.svg';
import LocationHM from '../../../stories/atoms/icon/assets/Location_HM.svg';
import { Animation, Animations } from '../../../stories/molecules';
import { Map, MarkerDetails } from '../../../stories/organisms';
import { homeTranslations } from '../../../translations';
import { isFailureResponse } from '../../../utils';
import {
  MapContainer,
  StoreChip,
  StoreContainer,
  StyledContainer,
  StyledErrorContainer,
  Wrapper,
} from './MyStoreMap.styles';

export const MyStoreMap: FC = observer(() => {
  const { t } = useTranslation();

  const {
    get: { storesDetails, currentStoreId },
  } = useUser();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  });

  const { fetchNearbyStores } = useStores();

  const {
    result: nearbyStores,
    isLoading: nearbyStoresLoading,
    isError: nearbyStoresError,
    triggerAction: triggerFetchNearbyStores,
  } = useAsyncAction<StoreDetails[]>();

  const [selectedStoreId, setSelectedStoreId] = useState<string>(currentStoreId);
  const [markersDetails, setMarkersDetails] = useState<MarkerDetails[]>([]);

  const handleMarkerClick = (storeId: string) => {
    setSelectedStoreId(storeId);
  };

  const fetchNearbyStoresData = async () => {
    const response = await fetchNearbyStores();

    if (isFailureResponse(response)) {
      throw Error();
    }

    return response.data.slice(0, 10);
  };

  const updateMarkers = () => {
    if (!storesDetails) return;

    const newMarkersDetails: MarkerDetails[] = [];

    const currentStore = storesDetails.find((store) => store.storeId === currentStoreId);

    if (currentStore) {
      newMarkersDetails.push({
        latitude: Number(currentStore?.latitude),
        longitude: Number(currentStore?.longitude),
        id: currentStoreId,
      });
    }

    if (nearbyStores) {
      nearbyStores.forEach((nearbyStore) => {
        newMarkersDetails.push({
          latitude: Number(nearbyStore?.latitude),
          longitude: Number(nearbyStore?.longitude),
          id: nearbyStore.storeId,
        });
      });
    }

    setMarkersDetails(newMarkersDetails);
  };

  useEffect(() => {
    triggerFetchNearbyStores(fetchNearbyStoresData);
  }, []);

  useEffect(() => {
    updateMarkers();
  }, [nearbyStores]);

  const getLocationMarkerIcon = (storeId?: string) => {
    const targetStoreDetails = storesDetails?.find((storeDetails) => storeDetails.storeId === storeId);

    let corporateBrandId = 0;

    if (targetStoreDetails) {
      corporateBrandId = targetStoreDetails.corporateBrandId;
    }

    const isActive = selectedStoreId && selectedStoreId === storeId;

    return corporateBrandId === 0
      ? isActive
        ? LocationActiveHM
        : LocationHM
      : isActive
      ? LocationActiveIcon
      : LocationIcon;
  };

  const center = useMemo(() => {
    const currentStore = storesDetails.find((store) => store.storeId === currentStoreId);

    if (!currentStore) {
      return { lat: 0, lng: 0 };
    }

    return {
      lat: Number(currentStore?.latitude),
      lng: Number(currentStore?.longitude),
    };
  }, [storesDetails]);

  return (
    <Wrapper>
      {nearbyStoresError && (
        <StyledErrorContainer data-testid="error-my-store-widget">
          <Animation title="" animation={Animations.SAD} size="small" />
        </StyledErrorContainer>
      )}

      {(!isLoaded || nearbyStoresLoading) && <SkeletonLoader variant="rect" height="100%" width="100%" />}

      {isLoaded && !nearbyStoresLoading && (
        <StyledContainer wrap="nowrap" margin={[4, 5, 2]} data-testid="my-store-widget">
          <MapContainer data-testid="map-container">
            <Map
              prefferedProvider="Google"
              zoom={11}
              center={{
                latitude: center.lat,
                longitude: center.lng,
              }}
              shouldFitMarkers={false}
              data-testid="google-map"
              markers={markersDetails}
              onMarkerClick={handleMarkerClick}
              selectedMarkerId={selectedStoreId}
              getMarkerIconUrl={getLocationMarkerIcon}
              panOnMarkerSelect
            />
          </MapContainer>

          <StoreContainer padding={[2, 3]} data-testid="store-container">
            <Typography data-testid="store-name" type="button" overflow="visible">
              {storesDetails.find((store) => store.storeId === currentStoreId)?.name}
            </Typography>

            <Typography data-testid="store-address" type="legal" overflow="visible" margin={[1, 0, 2]}>
              {storesDetails.find((store) => store.storeId === currentStoreId)?.address}
            </Typography>

            {nearbyStores && nearbyStores.length !== 0 && (
              <Container direction="vertical" wrap="nowrap" margin={[1, 0, 0, 0]} space="between" width="100%">
                <Typography>
                  {t(homeTranslations.common.nearby.key, homeTranslations.common.nearby.defaultValue)}
                </Typography>

                <Container direction="horizontal" position="left" data-testid="nearby-store-container">
                  {nearbyStores.map((store, index) => (
                    <Tooltip
                      key={`${store.storeId}-${index}`}
                      position="bottom-start"
                      arrowedToolTip
                      text={
                        <Container padding={[2]}>
                          <Typography color="white">{store.name}</Typography>
                          <Typography color="white" type="legal">
                            {store.address}
                          </Typography>
                        </Container>
                      }
                    >
                      <StoreChip
                        onClick={() => {
                          setSelectedStoreId(store.storeId);
                        }}
                      >
                        <Typography type="caption">{store.storeId}</Typography>
                      </StoreChip>
                    </Tooltip>
                  ))}
                </Container>
              </Container>
            )}
          </StoreContainer>
        </StyledContainer>
      )}
    </Wrapper>
  );
});
