import styled from 'styled-components';
import { Icon, Typography } from '../../../../../stories/atoms';

export const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: ${({ theme: { spacing } }) => spacing(2)};
`;

export const ChartContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledIcon = styled(Icon)`
  width: ${({ theme: { spacing } }) => spacing(3)};
  height: ${({ theme: { spacing } }) => spacing(3)};
`;

export const StyledChartIcon = styled(StyledIcon)`
  position: absolute;
  top: ${({ theme: { spacing } }) => spacing(1)};
  right: ${({ theme: { spacing } }) => spacing(1)};
`;

export const StyledChartTypography = styled(Typography)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
`;

export const StyledSection = styled.div`
  display: flex;
  border-radius: ${({ theme: { spacing } }) => spacing(2)};
  border: 1px solid
    ${({ theme: { color, palette } }) => (palette.mode === 'dark' ? color.borderGrayDark : color.borderGray)};
  padding: ${({ theme: { spacing } }) => spacing(1, 3)};
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;
