import { StorePerformanceInterval } from '../../constants';
import { Store, StoreDetails } from '../../global-state/types';

import {
  AreaComparisonItem,
  AssetManagementResponse,
  ConversionRatePerformanceItem,
  HourlyTurnoverPerformanceItem,
  NewMembersPerformanceItem,
  ProfitLossResponse,
  SalesEfficiencyMetrics,
  ShareOfReceiptsItem,
  Status,
  StorePerformanceItem,
  Tills,
} from '../../hooks';

import { getTodayPerformanceItems } from '../../utils/storePerformanceChart';
import { getRandomNumber } from './profit-and-loss.mock';
import {
  getConversionRateData,
  getHourlyTournoverData,
  getMockSCOShareOfReceiptsData,
  getMockShareOfReceiptsData,
  getNewMembersData,
  getQueueLengthForecastData,
  getSCOHourlyTournoverData,
} from './transaction-search.mock';

const timing1 = {
  open: {
    opens: '10:00',
    closes: '19:00',
  },
  exception: {
    opens: '',
    closes: '',
  },
};

const timing2 = {
  open: {
    opens: '10:00',
    closes: '17:00',
  },
  exception: {
    opens: '',
    closes: '',
  },
};

const openingHours = {
  mon: timing1,
  tue: timing2,
  wed: timing1,
  thu: timing1,
  fri: timing2,
  sat: timing2,
  sun: timing1,
};

const openingHourExceptions = {
  item: [
    { date: '2022-11-22', openingHours: { opens: '10:00', closes: '21:00' }, isClosedAllDay: false },
    { date: '2022-11-24', openingHours: { opens: '10:00', closes: '22:00' }, isClosedAllDay: false },
    { date: '2022-11-26', openingHours: { opens: '10:00', closes: '18:00' }, isClosedAllDay: true },
  ],
};

export const mockStoresDetails: StoreDetails[] = [
  {
    storeId: 'SE0038',
    name: 'H&M - Drottninggatan 56 - Stockholm',
    area: 'EW Area SE',
    longitude: '11.96734995286636',
    latitude: '57.70456331254925',
    address: 'Stockholm',
    timezone: 'Europe/Stockholm',
    status: 'OPEN',
    corporateBrandId: 0,
  },
  {
    storeId: 'SE0144',
    name: 'H&M - Mall Of Scandinavia - Solna',
    area: 'Box Area Demo',
    longitude: '18.003338',
    latitude: '59.370186',
    address: 'Evenemangsgatan 14C, Solna, 16979',
    timezone: 'Europe/Stockholm',
    status: 'CLOSED',
    corporateBrandId: 1,
  },
];

export const mockStores: Store[] = [
  {
    id: '1ec4df22-9fa4-4b69-b1f8-d51c781e922c',
    storeId: 'SE0038',
    area: 'SE Mälardalen',
    popularName: 'H&M - Drottninggatan 56 - Stockholm',
    countryCode: 'SE',
    status: 'OPEN',
    currency: 'SEK',
    openingHours: { ...openingHours },
    openingHourExceptions: { ...openingHourExceptions },
    kpis: [
      { name: 'dailyConversionRate', target: 20, locked: true },
      { name: 'dailyTurnOver', target: 10000 },
      { name: 'dailyNewMembers', target: 5, locked: true },
      { name: 'dailyShareOfReceipts', target: 10 },
    ],
    corporateBrandId: 0,
  },
  {
    id: '24f9315e-cd43-4bfc-af4c-4f9e82d5b16c',
    storeId: 'SE0663',
    area: 'SE Mälardalen',
    popularName: 'Weekday - Kungsgatan 51',
    countryCode: 'SE',
    currency: 'SEK',
    status: 'OPEN',
    openingHours: { ...openingHours },
    openingHourExceptions: { ...openingHourExceptions },
    kpis: [
      { name: 'dailyConversionRate', target: 25 },
      { name: 'dailyTurnOver', target: 10000 },
      { name: 'dailyNewMembers', target: 8 },
      { name: 'dailyShareOfReceipts', target: 10 },
    ],
    corporateBrandId: 0,
  },
];

const mockReceiptType = ['OnlineReturn', 'Sales', 'Return'];
const mockOperatorIds = ['A110001', 'A110003', 'A110004'];
const mockNames = ['POS#0001', 'POS#0002', 'POS#0003', 'POS#0004', 'POS#0005', 'POS#0006'];
const statuses = ['OK', 'WARNING', 'UNKNOWN'];

const getMockReceiptType = () => {
  return mockReceiptType[Math.floor(Math.random() * mockReceiptType.length)];
};

const getMockOperatorId = () => {
  return mockOperatorIds[Math.floor(Math.random() * mockOperatorIds.length)];
};

const getMockName = () => {
  return mockNames[Math.floor(Math.random() * mockNames.length)];
};
const getStatus = () => {
  return statuses[Math.floor(Math.random() * statuses.length)];
};

const mockTotalAmount = [189, 200, 201, 450, 234, 543];

export const getMockAssetManagementData = (noOfTills = 6): AssetManagementResponse => {
  const tills: Tills[] = [];

  for (let i = 0; i < noOfTills; i++) {
    const status = getStatus() as Status;

    const till: Tills = {
      name: getMockName(),
      amounts: [
        { amount: 169, currency: 'SEK' },
        { amount: 20, currency: 'USD' },
      ],
      totalAmount: mockTotalAmount[Math.floor(Math.random() * mockTotalAmount.length)],
      userLog: [
        {
          lastSeen: new Date().toISOString(),
          operatorId: getMockOperatorId(),
        },
        {
          lastSeen: new Date().toISOString(),
          operatorId: getMockOperatorId(),
        },
      ],
      status,
    };

    if (status !== 'UNKNOWN') {
      till.latestTransaction = {
        timestamp: new Date().toISOString(),
        type: getMockReceiptType(),
      };
    }

    tills.push(till);
  }
  return {
    tills,
    lastUpdated: new Date(),
  };
};

export const getMockHighlightedWidgets = () => {
  return ['dailyShareOfReceipts', 'dailyTurnOver', 'dailyNewMembers'];
};

export const getMockTills = () => {
  return getMockAssetManagementData().tills;
};

export const getMockWeatherData = () => {
  const data = {
    name: 'Eskilstuna',
    situation: 'overcast clouds',
    icon: 'cloudy',
    units: 'Celsius',
    temperature: 2,
    temperatureHigh: 2,
    temperatureLow: 3,
  };
  return data;
};

export const forecastData = (kpi: string): StorePerformanceItem[] => {
  let storePerformanceItems: StorePerformanceItem[] = [];
  let storePerformanceCompleteItems: StorePerformanceItem[] = [];

  const kpiGetHasValue = (currentKpi: string, item: StorePerformanceItem) => {
    if (currentKpi === 'newMembers') return (item as NewMembersPerformanceItem).totalTransactions !== 0;

    if (currentKpi === 'shareOfReceipt') return (item as ShareOfReceiptsItem).totalTransactions !== 0;

    if (currentKpi === 'netSale') return (item as HourlyTurnoverPerformanceItem).amount !== 0;

    if (currentKpi === 'conversionRate')
      return (
        (item as ConversionRatePerformanceItem).payingCustomers !== 0 ||
        (item as ConversionRatePerformanceItem).nonPayingCustomers !== 0
      );
    return false;
  };

  if (kpi === 'newMembers') {
    storePerformanceItems = getNewMembersData().newMembers;
    storePerformanceCompleteItems = getNewMembersData(false).newMembers;
  }

  if (kpi === 'shareOfReceipt') {
    storePerformanceItems = getMockShareOfReceiptsData().shareOfReceipts;
    storePerformanceCompleteItems = getMockShareOfReceiptsData(false).shareOfReceipts;
  }

  if (kpi === 'netSale') {
    storePerformanceItems = getHourlyTournoverData().netSales;
    storePerformanceCompleteItems = getHourlyTournoverData(false).netSales;
  }

  if (kpi === 'conversionRate') {
    storePerformanceItems = getConversionRateData().conversionRate;
    storePerformanceCompleteItems = getConversionRateData(false).conversionRate;
  }

  if (kpi === 'shareOfReceiptSCO') {
    storePerformanceItems = getMockSCOShareOfReceiptsData().shareOfReceiptsSCO;
    storePerformanceCompleteItems = getMockSCOShareOfReceiptsData(false).shareOfReceiptsSCO;
  }

  if (kpi === 'netSaleSCO') {
    storePerformanceItems = getSCOHourlyTournoverData().netSalesSCO;
    storePerformanceCompleteItems = getSCOHourlyTournoverData(false).netSalesSCO;
  }

  if (kpi === 'queueLength') {
    return getQueueLengthForecastData();
  }

  const filteredPerformanceData = getTodayPerformanceItems({
    interval: StorePerformanceInterval.DAILY,
    currentUTCTime: new Date(),
    storeTimezone: mockStoresDetails[0].timezone,
    storePerformanceItems: storePerformanceItems,
    getHasValue: (item) => kpiGetHasValue(kpi, item),
  });

  const forecastItems: StorePerformanceItem[] = [];

  storePerformanceCompleteItems.forEach((item, index) => {
    if (filteredPerformanceData[index]) return;

    forecastItems.push(item);
  });

  return forecastItems;
};

export const getMockAreaComparisonData = (): AreaComparisonItem[] => {
  const metrics = [
    {
      name: 'averageNetSale',
      min: 50,
      max: 100,
      factor: 1000,
    },
    {
      name: 'averageVisitors',
      min: 1000,
      max: 2000,
      decimals: 0,
    },
    {
      name: 'averageConversionRate',
      min: 15,
      max: 30,
    },
    {
      name: 'averageTakt1',
      min: 800,
      max: 1200,
    },
    {
      name: 'averagePiecesPerReceipt',
      min: 1,
      max: 3,
    },
    {
      name: 'averageSPT',
      min: 5,
      max: 15,
    },
    {
      name: 'averagePurchasePerReceipt',
      min: 200,
      max: 400,
    },
    {
      name: 'averageEffectiveHours',
      min: 40,
      max: 60,
    },
  ];

  const data = metrics.map((metric) => ({
    name: metric.name as SalesEfficiencyMetrics,
    today: getRandomNumber(
      metric.min * (metric.factor || 1),
      metric.max * (metric.factor || 1),
      metric.decimals || 2,
    ),
    lastWeek: getRandomNumber(
      metric.min * (metric.factor || 1),
      metric.max * (metric.factor || 1),
      metric.decimals || 2,
    ),
    lastYear: getRandomNumber(
      metric.min * (metric.factor || 1),
      metric.max * (metric.factor || 1),
      metric.decimals || 2,
    ),
    lastWeekArea: getRandomNumber(
      metric.min * (metric.factor || 1),
      metric.max * (metric.factor || 1),
      metric.decimals || 2,
    ),
    lastYearArea: getRandomNumber(
      metric.min * (metric.factor || 1),
      metric.max * (metric.factor || 1),
      metric.decimals || 2,
    ),
  }));

  return data;
};

export const getMockProfitLossData = (): ProfitLossResponse => {
  const data = {
    lastUpdated: new Date(),
    salesExcVat: {
      actual: 2107905.51,
      budget: 2323678.8726,
      lastYear: 0,
    },
    salesIncVat: {
      actual: 2634887.31,
      budget: 2903902.04,
      lastYear: 0,
    },
    details: {
      otherCosts: [
        {
          name: 'Other selling expenses',
          actual: 16208.22,
          budget: 261.37,
          lastYear: 0,
        },
        {
          name: 'Cash differences',
          actual: 12,
          budget: 262.916,
          lastYear: 0,
        },
        {
          name: 'Office supplies and printed m',
          actual: 1735.75,
          budget: 5532.6771,
          lastYear: 0,
        },
        {
          name: 'IT',
          actual: 40730.78,
          budget: 13329.3041,
          lastYear: 0,
        },
        {
          name: 'Credit cards and Bank Costs',
          actual: 10993.05,
          budget: 10851.4829,
          lastYear: 0,
        },
        {
          name: 'Other external services, Fees',
          actual: 1871.92,
          budget: 1027.7838,
          lastYear: 0,
        },
        {
          name: 'Shopfitting and Bulbs',
          actual: 28.95,
          budget: 3115.9585,
          lastYear: 0,
        },
        {
          name: 'Security and other risk-relat',
          actual: 1706.25,
          budget: 5942.918,
          lastYear: 0,
        },
        {
          name: 'Maintenance on premises',
          actual: 2697.62,
          budget: 4764.59,
          lastYear: 0,
        },
        {
          name: 'Electricity',
          actual: 9025.99,
          budget: 18260,
          lastYear: 0,
        },
        {
          name: 'Consumable equipment and supp',
          actual: 2150.18,
          budget: 4730.2556,
          lastYear: 0,
        },
        {
          name: 'Hangers',
          actual: 1654.29,
          budget: 1705.1229,
          lastYear: 0,
        },
        {
          name: 'Cleaning, Pest and Waste',
          actual: 18378,
          budget: 28510.35,
          lastYear: 0,
        },
        {
          name: 'Travel expenses',
          actual: 161.7,
          budget: 0,
          lastYear: 0,
        },
        {
          name: 'Freight and transport',
          actual: 0,
          budget: 531.32,
          lastYear: 0,
        },
      ],
      reductions: [
        {
          name: 'Reduction cosmetics',
          actual: 5682.57,
          budget: 5486.6563,
          lastYear: 0,
        },
        {
          name: 'Reduction textile',
          actual: 102179.28,
          budget: 95528.8734,
          lastYear: 0,
        },
      ],
      salaries: [
        {
          name: 'Fixed salaries',
          actual: 428091.89,
          budget: 373618.2375,
          lastYear: 0,
        },
        {
          name: 'Flexible salaries',
          actual: 71847.46,
          budget: 66097.5371,
          lastYear: 0,
        },
        {
          name: 'Vacation',
          actual: 48434.14,
          budget: 45732.385,
          lastYear: 0,
        },
        {
          name: 'Illness',
          actual: 12095.84,
          budget: 1516.7174,
          lastYear: 0,
        },
        {
          name: 'Overtime',
          actual: 235.8,
          budget: 99.199,
          lastYear: 0,
        },
        {
          name: 'Other personnel costsEmploye',
          actual: 3583.15,
          budget: 5829.924,
          lastYear: 0,
        },
      ],
      scrapping: [
        {
          name: 'scrapping',
          actual: 5016.42,
          budget: 13148.9884,
          lastYear: 0,
        },
      ],
      surplus1: [
        {
          name: 'surplus1',
          actual: 144132.55,
          budget: 406477.7656,
          lastYear: 0,
        },
      ],
      shrinkage: [
        {
          name: 'shrinkage',
          actual: 41858.6,
          budget: 43157,
          lastYear: 0,
        },
      ],
    },
  };

  return data;
};
