import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createStorePerformanceRequest, StorePerformanceInterval } from '../../../../../constants';
import {
  BudgetPlannedData,
  EffectiveHoursResponse,
  ReceiptSalesAnalysisResponse,
  useAsyncAction,
  useTransaction,
  useUser,
} from '../../../../../hooks';
import { Container, Icon, Icons, Tooltip, Typography } from '../../../../../stories/atoms';
import { DropdownMenu } from '../../../../../stories/molecules';
import { ContainerDrawer } from '../../../../../stories/molecules/container-drawer/ContainerDrawer';
import { muiTheme } from '../../../../../theme';
import { homeTranslations } from '../../../../../translations';
import { formatAmountWithSeparator, isFailureResponse } from '../../../../../utils';
import { calculateTrend } from '../../../../../utils/budgetPlanned';
import {
  BudgetPlannedContent,
  getBudgetAndPlannedPermissions,
} from '../../../common/budgetPlannedContent/BudgetPlannedContent';
import { StyledTrendIconComponent } from '../../../common/styledTrendIcon/StyledTrendIcon';
import { WidgetCardContent } from '../../../common/widgetCardContent/WidgetCardContent';
import {
  MetricSection,
  SectionHeader,
  StyledEfficiencyWrapper,
  StyledIcon,
  StyledSection,
  StyledTypography,
  StyledWrapper,
} from './EfficiencyWidget.styles';

type BudgetPlannedSliderType = 'Takt1Slider' | 'SptSlider' | 'EffectiveHoursSlider';
const budgetPlannedSliderTypes: BudgetPlannedSliderType[] = ['Takt1Slider', 'SptSlider', 'EffectiveHoursSlider'];

const { common, efficiencyWidget: efficiencyWidgetTranslations, budgetPlanned, salesNet } = homeTranslations;

type EfficiencyWidgetProps = {
  isToday: boolean;
  currentDate: Date;
  interval: StorePerformanceInterval;
  fromDate?: Date;
  toDate?: Date;
};

type MetricSectionProps = {
  metricName: string;
  icon: Icons;
  actual: number;
  lastWeek: number;
  lastYear: number;
  isEffectiveHours?: boolean;
  isToday?: boolean;
};

type EfficiencyMetricNames = 'takt1' | 'spt' | 'effectiveHours';

const EfficiencyMetricsSection: FC<MetricSectionProps> = ({
  metricName,
  icon,
  actual,
  lastWeek,
  lastYear,
  isToday = false,
  isEffectiveHours = false,
}) => {
  const { t } = useTranslation();
  const isMobileView = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));

  return (
    <StyledSection data-testid={`${metricName}-card`}>
      <MetricSection isMobileView={isMobileView}>
        <SectionHeader>
          <StyledIcon icon={icon} />
          <Typography type="button">
            {t(
              efficiencyWidgetTranslations[metricName as EfficiencyMetricNames].title.key,
              efficiencyWidgetTranslations[metricName as EfficiencyMetricNames].title.defaultValue,
            )}{' '}
          </Typography>
          {isEffectiveHours && (
            <Typography type="caption">
              (
              {isToday
                ? t(budgetPlanned.planned.key, budgetPlanned.planned.defaultValue)
                : t(salesNet.actual.key, salesNet.actual.defaultValue)}
              )
            </Typography>
          )}
          {isMobileView && (
            <Tooltip
              position="bottom-start"
              text={
                <Typography color="white">
                  {t(
                    efficiencyWidgetTranslations[metricName as EfficiencyMetricNames].text.key,
                    efficiencyWidgetTranslations[metricName as EfficiencyMetricNames].text.defaultValue,
                  )}
                </Typography>
              }
            >
              <Icon data-testid="header-icon" icon={Icons.INFO} size="small" margin={[0, 0, 0, 1]} />
            </Tooltip>
          )}
        </SectionHeader>

        <Typography type={isMobileView ? 'body2' : 'h4'}>
          {actual.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Typography>
      </MetricSection>
      {!isMobileView && (
        <StyledTypography>
          {t(
            efficiencyWidgetTranslations[metricName as EfficiencyMetricNames].text.key,
            efficiencyWidgetTranslations[metricName as EfficiencyMetricNames].text.defaultValue,
          )}
        </StyledTypography>
      )}
      <MetricSection isMobileView={isMobileView}>
        <Typography type="caption" color="textGray" margin={[2, 0, 1]}>
          {t(common.lastWeek.key, common.lastWeek.defaultValue)}
        </Typography>
        <div>
          <StyledTrendIconComponent
            originalAmount={actual}
            previousAmount={lastWeek}
            direction="horizontal"
            valueFormatter={(value) => formatAmountWithSeparator(value)}
            textOptions={{ type: isMobileView ? 'body2' : 'button' }}
            calculateCustomTrend={(originalAmount, previousAmount) =>
              calculateTrend(originalAmount, previousAmount, isEffectiveHours)
            }
            fullWidth
          />
        </div>
      </MetricSection>
      <MetricSection isMobileView={isMobileView}>
        <Typography type="caption" color="textGray" margin={[2, 0, 1]}>
          {t(common.lastYear.key, common.lastYear.defaultValue)}
        </Typography>
        <div>
          <StyledTrendIconComponent
            originalAmount={actual}
            previousAmount={lastYear}
            direction="horizontal"
            valueFormatter={(value) => formatAmountWithSeparator(value)}
            textOptions={{ type: isMobileView ? 'body2' : 'button' }}
            calculateCustomTrend={(originalAmount, previousAmount) =>
              calculateTrend(originalAmount, previousAmount, isEffectiveHours)
            }
            fullWidth
          />
        </div>
      </MetricSection>
    </StyledSection>
  );
};

export const EfficiencyWidget: FC<EfficiencyWidgetProps> = observer(
  ({ isToday, currentDate, interval, fromDate, toDate }) => {
    const { t } = useTranslation();
    const {
      get: { userPermissions },
    } = useUser();

    const isMobileView = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));

    const [isBudgetSliderOpen, setIsBudgetSliderOpen] = useState(false);
    const [selectedSlider, setSelectedSlider] = useState<BudgetPlannedSliderType>('Takt1Slider');

    const { canViewBudget, canViewPlanned, showBudgetAndPlanned } =
      getBudgetAndPlannedPermissions(userPermissions);

    const {
      fetchEffectiveHoursData,
      fetchReceiptSalesAnalysisData,
      fetchEffectiveHoursBudget,
      fetchTakt1Budget,
      fetchSptBudget,
    } = useTransaction();

    // Trigger actual data
    // TODO: change this to single fetch when BE creates new endpoint for efficiency. [BTCOBUI-2751]
    const {
      result: effectiveHours,
      isLoading: effectiveHoursLoading,
      isError: effectiveHoursError,
      triggerAction: triggerFetchEffectiveHours,
    } = useAsyncAction<EffectiveHoursResponse>();

    const {
      result: receiptSalesAnalysisData,
      isLoading: receiptSalesAnalysisLoading,
      isError: receiptSalesAnalysisError,
      triggerAction: triggerFetchReceiptSalesAnalysis,
    } = useAsyncAction<ReceiptSalesAnalysisResponse>();

    // Trigger Budget and Planned data
    const {
      result: effectiveHoursBudgetPlanned,
      isLoading: effectiveHoursBudgetPlannedLoading,
      isError: effectiveHoursBudgetPlannedError,
      triggerAction: triggerFetchEffectiveHoursBudgetPlanned,
    } = useAsyncAction<BudgetPlannedData>();

    const {
      result: takt1BudgetPlanned,
      isLoading: takt1BudgetPlannedLoading,
      isError: takt1BudgetPlannedError,
      triggerAction: triggerFetchTakt1BBudgetPlanned,
    } = useAsyncAction<BudgetPlannedData>();

    const {
      result: sptBudgetPlanned,
      isLoading: sptBudgetPlannedLoading,
      isError: sptBudgetPlannedError,
      triggerAction: triggerFetchSptBudgetPlanned,
    } = useAsyncAction<BudgetPlannedData>();

    const fetchReceiptSalesAnalysis = async () => {
      const request = createStorePerformanceRequest({
        interval,
        ...(interval === StorePerformanceInterval.DAILY ? { currentDate } : { fromDate, toDate }),
      });
      const response = await fetchReceiptSalesAnalysisData(request);

      if (isFailureResponse(response)) {
        throw new Error();
      }

      return {
        takt1: response.data.takt1,
        spt: response.data.spt,
      };
    };

    const fetchEffectiveHours = async () => {
      // Fetch the request based on interval
      const request = createStorePerformanceRequest({
        interval,
        ...(interval === StorePerformanceInterval.DAILY ? { currentDate } : { fromDate, toDate }),
      });

      const response = await fetchEffectiveHoursData(request);

      if (isFailureResponse(response)) {
        throw new Error();
      }

      return response.data;
    };

    const fetchBudgetPlanned = async (budgetType: EfficiencyMetricNames) => {
      const request = createStorePerformanceRequest({
        interval,
        ...(interval === StorePerformanceInterval.DAILY ? { currentDate } : { fromDate, toDate }),
      });

      let response;

      if (budgetType === 'takt1') {
        response = await fetchTakt1Budget(request);
      } else if (budgetType === 'spt') {
        response = await fetchSptBudget(request);
      } else {
        response = await fetchEffectiveHoursBudget(request);
      }

      if (isFailureResponse(response)) {
        throw new Error();
      }

      return response.data;
    };

    const fetchWidgetData = () => {
      triggerFetchReceiptSalesAnalysis(fetchReceiptSalesAnalysis);
      triggerFetchEffectiveHours(fetchEffectiveHours);
      triggerFetchTakt1BBudgetPlanned(() => fetchBudgetPlanned('takt1'));
      triggerFetchSptBudgetPlanned(() => fetchBudgetPlanned('spt'));
      triggerFetchEffectiveHoursBudgetPlanned(() => fetchBudgetPlanned('effectiveHours'));
    };

    useEffect(() => {
      fetchWidgetData();
      setIsBudgetSliderOpen(false);
    }, [currentDate, interval]);

    const currentEffectiveHours = isToday ? effectiveHoursBudgetPlanned?.planned ?? 0 : effectiveHours?.today ?? 0;

    const isNoData =
      (!!receiptSalesAnalysisData &&
        receiptSalesAnalysisData.takt1.today === 0 &&
        receiptSalesAnalysisData.spt.today === 0 &&
        !!effectiveHours &&
        currentEffectiveHours === 0) ||
      currentEffectiveHours < 1;

    return (
      <ContainerDrawer
        isOpen={isBudgetSliderOpen}
        onClose={() => setIsBudgetSliderOpen(false)}
        sliderContent={
          <>
            {selectedSlider === 'Takt1Slider' && takt1BudgetPlanned && (
              <BudgetPlannedContent
                title={t(
                  efficiencyWidgetTranslations.takt1.title.key,
                  efficiencyWidgetTranslations.takt1.title.defaultValue,
                )}
                currentData={receiptSalesAnalysisData?.takt1.today ?? null}
                isLoading={takt1BudgetPlannedLoading}
                isError={takt1BudgetPlannedError}
                canViewBudget={canViewBudget}
                canViewPlanned={canViewPlanned}
                budgetPlanned={takt1BudgetPlanned}
                budgetNoData={takt1BudgetPlanned.budget === 0}
                plannedNoData={takt1BudgetPlanned.planned === 0}
              />
            )}

            {selectedSlider === 'SptSlider' && sptBudgetPlanned && (
              <BudgetPlannedContent
                title={t(
                  efficiencyWidgetTranslations.spt.title.key,
                  efficiencyWidgetTranslations.spt.title.defaultValue,
                )}
                currentData={receiptSalesAnalysisData?.spt.today ?? null}
                isLoading={sptBudgetPlannedLoading}
                isError={sptBudgetPlannedError}
                canViewBudget={canViewBudget}
                canViewPlanned={canViewPlanned}
                budgetPlanned={sptBudgetPlanned}
                budgetNoData={sptBudgetPlanned.budget === 0}
                plannedNoData={sptBudgetPlanned.planned === 0}
              />
            )}

            {selectedSlider === 'EffectiveHoursSlider' && effectiveHoursBudgetPlanned && (
              <BudgetPlannedContent
                title={t(
                  efficiencyWidgetTranslations.effectiveHours.title.key,
                  efficiencyWidgetTranslations.effectiveHours.title.defaultValue,
                )}
                currentData={effectiveHours?.today ?? null}
                isLoading={effectiveHoursBudgetPlannedLoading}
                isError={effectiveHoursBudgetPlannedError}
                canViewBudget={canViewBudget}
                canViewPlanned={canViewPlanned}
                budgetPlanned={effectiveHoursBudgetPlanned}
                budgetNoData={effectiveHoursBudgetPlanned.budget === 0}
                plannedNoData={effectiveHoursBudgetPlanned.planned === 0}
                isEffectiveHours
              />
            )}
          </>
        }
        width={isMobileView ? '250px' : '300px'}
      >
        <WidgetCardContent
          headerIcon={Icons.SALESEFFICIENCY}
          headerText={t(efficiencyWidgetTranslations.title.key, efficiencyWidgetTranslations.title.defaultValue)}
          subHeaderText={t(
            efficiencyWidgetTranslations.subTitle.key,
            efficiencyWidgetTranslations.subTitle.defaultValue,
          )}
          toolTipText={t(
            efficiencyWidgetTranslations.tooltip.key,
            efficiencyWidgetTranslations.tooltip.defaultValue,
          )}
          lastUpdatedDate={effectiveHours?.lastUpdated} // TODO: change this to single last updated when BE creates new endpoint for effciency. [BTCOBUI-2751]
          isLoading={receiptSalesAnalysisLoading || effectiveHoursLoading}
          isError={receiptSalesAnalysisError || effectiveHoursError}
          errorText={t(efficiencyWidgetTranslations.error.key, efficiencyWidgetTranslations.error.defaultValue)}
          errorRetryHandler={fetchWidgetData}
          isNoData={isNoData}
          noDataText={t(efficiencyWidgetTranslations.noData.key, efficiencyWidgetTranslations.noData.defaultValue)}
          headerSideContent={
            <StyledWrapper>
              <DropdownMenu
                data-testid="budget-planned-dropdown"
                dropdownLabel={''}
                buttonContentProps={{
                  iconOptions: { customIcon: <Icon icon={Icons.MORE} /> },
                }}
                menuItems={[
                  {
                    key: budgetPlannedSliderTypes[0],
                    disabled: !showBudgetAndPlanned || takt1BudgetPlannedError,
                    hasTooltip: !showBudgetAndPlanned,
                    tooltipText: (
                      <Container padding={[1]}>
                        <Typography color="white">
                          {t(
                            budgetPlanned.noPermissionBudgetTooltip.key,
                            budgetPlanned.noPermissionBudgetTooltip.defaultValue,
                          )}
                        </Typography>
                      </Container>
                    ),
                    item: (
                      <Typography margin={[0, 2, 0]}>
                        {t(
                          efficiencyWidgetTranslations.takt1.budget.key,
                          efficiencyWidgetTranslations.takt1.budget.defaultValue,
                        )}
                      </Typography>
                    ),
                  },
                  {
                    key: budgetPlannedSliderTypes[1],
                    disabled: !showBudgetAndPlanned || sptBudgetPlannedError,
                    hasTooltip: !showBudgetAndPlanned,
                    tooltipText: (
                      <Container padding={[1]}>
                        <Typography color="white">
                          {t(
                            budgetPlanned.noPermissionBudgetTooltip.key,
                            budgetPlanned.noPermissionBudgetTooltip.defaultValue,
                          )}
                        </Typography>
                      </Container>
                    ),
                    item: (
                      <Typography margin={[0, 2, 0]}>
                        {t(
                          efficiencyWidgetTranslations.spt.budget.key,
                          efficiencyWidgetTranslations.spt.budget.defaultValue,
                        )}
                      </Typography>
                    ),
                  },
                  {
                    key: budgetPlannedSliderTypes[2],
                    disabled: !showBudgetAndPlanned || effectiveHoursBudgetPlannedError,
                    hasTooltip: !showBudgetAndPlanned,
                    tooltipText: (
                      <Container padding={[1]}>
                        <Typography color="white">
                          {t(
                            budgetPlanned.noPermissionBudgetTooltip.key,
                            budgetPlanned.noPermissionBudgetTooltip.defaultValue,
                          )}
                        </Typography>
                      </Container>
                    ),
                    item: (
                      <Typography margin={[0, 2, 0]}>
                        {t(
                          efficiencyWidgetTranslations.effectiveHours.budget.key,
                          efficiencyWidgetTranslations.effectiveHours.budget.defaultValue,
                        )}
                      </Typography>
                    ),
                  },
                ]}
                minWidth={'0px'}
                isCompact
                initialSelected={[]}
                onSelect={(selectedItems) => {
                  setSelectedSlider(selectedItems[0] as BudgetPlannedSliderType);
                  setIsBudgetSliderOpen(true);
                }}
                isOnlyIcon
                allowTooltip
              />
            </StyledWrapper>
          }
          content={
            <>
              <StyledEfficiencyWrapper data-testid="efficiency-widget-wrapper" isMobileView={isMobileView}>
                <EfficiencyMetricsSection
                  key="takt1"
                  metricName="takt1"
                  icon={Icons.DOLLAR}
                  actual={receiptSalesAnalysisData?.takt1.today || 0}
                  lastWeek={receiptSalesAnalysisData?.takt1.lastWeek || 0}
                  lastYear={receiptSalesAnalysisData?.takt1.lastYear || 0}
                />
                <EfficiencyMetricsSection
                  key="spt"
                  metricName="spt"
                  icon={Icons.BAG}
                  actual={receiptSalesAnalysisData?.spt.today || 0}
                  lastWeek={receiptSalesAnalysisData?.spt.lastWeek || 0}
                  lastYear={receiptSalesAnalysisData?.spt.lastYear || 0}
                />
                <EfficiencyMetricsSection
                  key="effectiveHours"
                  metricName="effectiveHours"
                  icon={Icons.CLOCK}
                  actual={currentEffectiveHours || 0}
                  lastWeek={effectiveHours?.lastWeek || 0}
                  lastYear={effectiveHours?.lastYear || 0}
                  isEffectiveHours
                  isToday={isToday}
                />
              </StyledEfficiencyWrapper>
            </>
          }
        />
      </ContainerDrawer>
    );
  },
);
