import { format, parse } from 'date-fns';
import { Props } from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { StorePerformanceInterval } from '../../../../constants';
import { StorePerformanceItem } from '../../../../hooks';
import { Charts } from '../../../../stories/molecules/charts/Charts';
import { homeTranslations } from '../../../../translations';

type WidgetChartProps<T extends StorePerformanceItem> = {
  performanceItemsChartName: string;
  performanceItems: T[];
  filteredPerformanceItems: (T | null)[];
  forecastPerformanceItems: (T | null)[];
  itemValueResolver: (storePerformanceItem: T) => number;
  type?: Props['type'];
  targetItems?: (number | null)[];
  showGridYAxis?: boolean;
  showXAxis?: boolean;
  showYAxis?: boolean;
  dataLabel?: ApexDataLabels;
  yAxisTickAmount?: number;
  yAxisMax?: number;
  xAxisResolver?: (item: StorePerformanceItem) => string;
  yAxisFormatter?: (val: number) => string;
  tooltipFormatter?: (val: number) => string;
  interval?: StorePerformanceInterval;
  customYAxis?: ApexYAxis;
};

export default function WidgetChart<T extends StorePerformanceItem>(props: WidgetChartProps<T>) {
  const {
    performanceItemsChartName,
    performanceItems,
    filteredPerformanceItems,
    forecastPerformanceItems,
    itemValueResolver,
    type = 'area',
    targetItems,
    showGridYAxis = true,
    showXAxis = false,
    showYAxis = true,
    dataLabel,
    yAxisTickAmount,
    yAxisMax,
    xAxisResolver,
    yAxisFormatter,
    tooltipFormatter,
    interval,
    customYAxis,
  } = props;

  const { t } = useTranslation();
  const { common: commonTranslations } = homeTranslations;

  const getSeriesType = (chartType: Props['type'], isForecast: boolean): Props['type'] => {
    switch (chartType) {
      case 'area':
        return !isForecast ? 'area' : 'line';
      case 'bar':
        return 'bar';
      default:
        return 'area';
    }
  };

  const chartSeries: ApexAxisChartSeries = [];

  let chartForecastCount = 0;

  const combinedPerformanceItems = filteredPerformanceItems.map((item, index) => {
    if (forecastPerformanceItems[index]) chartForecastCount += 1;
    return forecastPerformanceItems[index] ?? item;
  });

  chartSeries.push({
    name: performanceItemsChartName,
    type: getSeriesType(type, false),
    data: combinedPerformanceItems.map((item) => (item ? itemValueResolver(item) : null)),
  });

  if (targetItems) {
    chartSeries.push({
      name: performanceItemsChartName,
      type: 'line',
      data: targetItems,
    });
  }

  const xAxisCategories = showXAxis
    ? performanceItems.map((item) => {
        if (xAxisResolver) {
          return xAxisResolver(item);
        }
        if (interval === StorePerformanceInterval.DAILY) {
          return item.timeUnit;
        }
        const parsedDate = parse(item.timeUnit, 'yyyy-MM-dd', new Date());
        const isValidDate = !isNaN(parsedDate.getTime());

        return isValidDate ? format(parsedDate, 'EEE') : item.timeUnit; // Converting Date to day of the week
      })
    : [];

  return (
    <Charts
      chartType={type}
      areaChartGradient={type === 'bar' ? { opacity: 1 } : undefined}
      chartPlotOptions={{
        columnWidth: '12px',
        borderRadius: 4,
        dataLabels: {
          position: 'top',
        },
      }}
      isAnimationEnabled={false}
      xAxisCategories={xAxisCategories}
      chartSeries={chartSeries}
      chartColors={['validationGreen', 'tagBlue']}
      legend={{ show: false }}
      xAxis={{ labels: { show: showXAxis } }}
      chartForecast={{ count: chartForecastCount, fillOpacity: 0.05 }}
      showGridYAxis={showGridYAxis}
      yAxis={{
        max: yAxisMax,
        tickAmount: yAxisTickAmount,
        decimalsInFloat: 0,
        labels: {
          show: showYAxis,
          formatter: yAxisFormatter,
        },
        ...customYAxis,
      }}
      dashArray={[0, 4]}
      toolTip={{
        shared: true,
        intersect: false,
        y: {
          title: {
            formatter() {
              return '';
            },
          },
          formatter: function (value, { seriesIndex, dataPointIndex }) {
            const isForecastValue =
              chartForecastCount > 0 && dataPointIndex > combinedPerformanceItems.length - 1 - chartForecastCount;

            let prefix = '';

            if (seriesIndex > 0) {
              prefix = `${t(commonTranslations.hourlyTarget.key, commonTranslations.hourlyTarget.defaultValue)}: `;
            } else if (isForecastValue) {
              prefix = `${t(
                commonTranslations.forecast.key,
                commonTranslations.forecast.defaultValue,
              )} ${performanceItemsChartName}: `;
            } else {
              prefix = `${performanceItemsChartName}: `;
            }

            if (value === null || value == 0) return `${prefix}-`;

            if (!tooltipFormatter) return `${prefix}${value.toString()}`;

            return `${prefix}${tooltipFormatter(value)}`;
          },
        },
      }}
      dataLabel={dataLabel}
    />
  );
}
