import styled from 'styled-components';
import { Container, Typography } from '../../../../../stories/atoms';

export const StyledView = styled(Container)<{ hasBorder: boolean }>`
  height: 100%;
`;

export const StyledArticles = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledItem = styled.img`
  max-height: min(6vh, 6vw);
  padding-right: ${({ theme: { spacing } }) => spacing(4)};
  max-width: fit-content;
`;

export const StyledTypography = styled(Typography)`
  border-radius: ${({ theme: { spacing } }) => spacing(2)};
  background-color: ${({ theme: { color } }) => color.backgroundSelectedHover};
`;
