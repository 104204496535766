import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StoreSalesData } from '../../../hooks';
import { Typography } from '../../../stories/atoms';
import { Table } from '../../../stories/molecules/tablev2/Table';
import { salesAnalysisTranslation } from '../../../translations/salesAnalysis';
import { format24Hour } from '../../../utils';

type SalesTableProps = {
  items: StoreSalesData[];
  isLoading: boolean;
  showTotalForMarket?: boolean | null;
  isFilterSelected?: boolean;
  currentDate?: Date;
};

export const SalesTable = ({
  isLoading,
  items,
  showTotalForMarket = false,
  isFilterSelected,
  currentDate,
}: SalesTableProps) => {
  const maxEntries = items.length > 0 ? items.length : 500;
  const rowsPerPageOptions = [100, 250, maxEntries];
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(rowsPerPageOptions[0]);

  const { t } = useTranslation();

  useEffect(() => {
    setCurrentPageIndex(0);
  }, [isFilterSelected, currentDate, items]);

  useEffect(() => {
    if (!rowsPerPageOptions.includes(currentRowsPerPage)) {
      setCurrentRowsPerPage(rowsPerPageOptions[0]);
    }
  }, [rowsPerPageOptions]);

  // Filter hours to exclude starting and ending zero-sales hours
  const filteredHours = useMemo(() => {
    if (!items.length) return [];

    const allSales = items.flatMap((store) => store.hourlySales);

    const salesByHour = allSales.reduce((acc, { hour, netSale }) => {
      acc[hour] = acc[hour] || [];
      acc[hour].push(netSale);
      return acc;
    }, {} as Record<string, number[]>);

    const allHours = Object.keys(salesByHour).sort((a, b) => parseInt(a) - parseInt(b));

    // Find the first and last hour where net sales are non-zero across any store
    const startIndex = allHours.findIndex((hour) => salesByHour[hour].some((netSale) => netSale !== 0));
    const endIndex =
      allHours.length -
      1 -
      [...allHours].reverse().findIndex((hour) => salesByHour[hour].some((netSale) => netSale !== 0));

    if (startIndex === -1 || endIndex === -1) return allHours;
    return allHours.slice(startIndex, endIndex + 1);
  }, [items]);

  const headerItems = useMemo(() => {
    return [
      {
        id: 'storeId',
        content: (
          <Typography type="body3">
            {t(salesAnalysisTranslation.storeID.key, salesAnalysisTranslation.storeID.defaultValue)}
          </Typography>
        ),
      },
      ...filteredHours.map((hour) => ({
        id: hour,
        content: <Typography type="body3">{format24Hour(hour)}</Typography>,
      })),
    ];
  }, [filteredHours]);

  const hourlyTotals = useMemo(() => {
    if (!showTotalForMarket) return [];

    const totals = filteredHours.map((hour) => {
      return items.reduce((sum, store) => {
        const sale = store.hourlySales.find((hourlySale) => hourlySale.hour === hour);
        return sum + (sale ? sale.netSale : 0);
      }, 0);
    });

    return [
      {
        id: 'storeId',
        content: (
          <Typography type="body3">
            {t(salesAnalysisTranslation.total.key, salesAnalysisTranslation.total.defaultValue)}
          </Typography>
        ),
      },
      ...totals.map((total, index) => ({
        id: `hour-total-${filteredHours[index]}`,
        content: (
          <Typography type="body3">{`${
            total !== 0 ? `${total.toFixed(2)} ${items[0].currency}` : total
          } `}</Typography>
        ),
      })),
    ];
  }, [items]);

  return (
    <Table
      headerItems={headerItems}
      items={items || []}
      isLoading={isLoading}
      getRowItems={(store) => {
        return [
          {
            id: 'storeId',
            content: <Typography>{store.storeId}</Typography>,
          },
          ...filteredHours.map((hour) => {
            const sale = store.hourlySales.find((s) => s.hour === hour);

            return {
              id: `hour-${hour}`,
              content: (
                <Typography>
                  {`${
                    sale && sale.netSale !== 0 ? `${sale.netSale.toFixed(2)} ${store.currency}` : sale?.netSale
                  }`}
                </Typography>
              ),
            };
          }),
        ];
      }}
      onRowSelect={() => null}
      stickyHeader
      skeletonRowCount={10}
      rowsPerPage={currentRowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      currentPageIndex={currentPageIndex}
      onPageIndexChange={(pageIndex) => setCurrentPageIndex(pageIndex)}
      onRowsPerPageChange={(val) => setCurrentRowsPerPage(val)}
      footerItems={hourlyTotals}
    />
  );
};
