import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const MapContainer = styled.div<{ isVisible: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  z-index: ${({ isVisible }) => (isVisible ? '100' : '-100')};
`;

export const ButtonsContainer = styled.div`
  position: absolute;
  z-index: 999;
  display: flex;
  flex-direction: row;
  padding: ${({ theme: { spacing } }) => spacing(2)};
  gap: ${({ theme: { spacing } }) => spacing(1)};
`;
