import { useDecide } from '@hm-group/fabulous';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useRef, useState } from 'react';
import {
  KpiDropdownActions,
  KpiEditTypes,
  KpiModalViews,
  KpiToApiMapping,
  KpiType,
  StorePerformanceInterval,
  UpdateKpiRequest,
} from '../../../constants';
import { useEventContext } from '../../../global-state/eventContext';
import { KpiTargetsProps, useAsyncAction, useStores, useUser } from '../../../hooks';
import { getWeekBounds, isFailureResponse } from '../../../utils';
import KpiModal from '../../kpiModal/KpiModal';
import { KpiModalDetails } from '../../kpiTarget/KpiTarget';
import { WidgetType, WidgetsCategory } from '../types';
import { createGetIsWidgetVisible, createGetWidgetOrder } from '../util';
import AppCustomers from '../widgets/customerBaseValue/appCustomers/AppCustomers';
import { NewMembersWidget } from '../widgets/customerBaseValue/newMembers/NewMembersWidget';
import { SCOShareOfReceipts } from '../widgets/customerBaseValue/scoShareOfReceipts/SCOShareOfReceipts';
import { ShareOfReceipts } from '../widgets/customerBaseValue/shareOfReceipts/ShareOfReceipts';
import { TopSellingGarmentWidget } from '../widgets/customerBaseValue/topSellingGarment/TopSellingGarmentWidget';
import { CustomerFeedbackWidget } from '../widgets/customerExperience/customerFeedback/CustomerFeedbackWidget';
import { OrisCustomersWidget } from '../widgets/customerExperience/orisCustomers/OrisCustomersWidget';
import { QueueLengthWidget } from '../widgets/customerExperience/queueLength/QueueLengthWidget';
import { RfidApprovalsWidget } from '../widgets/customerExperience/rfidApprovals/RfidApprovalsWidget';
import { RfidSalesRetaggingWidget } from '../widgets/customerExperience/rfidSalesRetagging/RfidSalesRetaggingWidget';
import { RfidCountWidget } from '../widgets/customerExperience/rfidWidgets/rfidCountWidget/RfidCountWidget';
import { TopReturningGarmentWidget } from '../widgets/customerExperience/topReturningGarment/TopReturningGarment';
import { AssetManagementWidget } from '../widgets/other/assetManagement/AssetManagement';
import { RegisteredReceiptsWidget } from '../widgets/other/registeredReceipts/RegisteredReceiptsWidget';
import { TenderOverviewWidget } from '../widgets/other/tenderOverview/TenderOverviewWidget';
import AreaComparisonWidget from '../widgets/salesAndEfficiency/areaComparison/AreaComparison';
import { AveragePurchaseWidget } from '../widgets/salesAndEfficiency/averagePurchase/AveragePurchaseWidget';
import { ConversionRateWidget } from '../widgets/salesAndEfficiency/conversionRate/ConversionRateWidget';
import { EfficiencyWidget } from '../widgets/salesAndEfficiency/efficiency/EfficiencyWidget';
import { HourlyTurnoverWidget } from '../widgets/salesAndEfficiency/hourlyTurnover/HourlyTurnoverWidget';
import { PiecesPerReceiptWidget } from '../widgets/salesAndEfficiency/piecesPerReceipt/PiecesPerReceiptWidget';
import { SCOHourlyTurnoverWidget } from '../widgets/salesAndEfficiency/scoHourlyTurnover/SCOHourlyTurnoverWidget';
import { WidgetCard } from './WidgetCard';
import { Container } from './WidgetsContainer.styles';

export type WidgetEnable = {
  [key: string]: boolean;
};

export type WidgetsContainerProps = {
  showAreaWidget: boolean;
  selectedCategory: WidgetsCategory;
  selectedDate: Date;
  isToday: boolean;
  isNonSigninUser?: boolean;
  interval?: StorePerformanceInterval;
};

export const WidgetsContainer: FC<WidgetsContainerProps> = observer(
  ({
    showAreaWidget,
    selectedCategory,
    selectedDate,
    isToday,
    isNonSigninUser,
    interval = StorePerformanceInterval.DAILY,
  }) => {
    const {
      set,
      get: { highlightedKpiTargets, userPermissions, currentStoreId },
    } = useUser();

    const { transactionEventsCount } = useEventContext();
    const { fetchKpiTargets, updateKpiData } = useStores();

    const showAppCustomersWidget = process.env.NODE_ENV === 'development'; // update the condition when BE is ready

    const containerRef = useRef<HTMLDivElement | null>(null);
    const [highestWidgetOrder, setHighestWidgetOrder] = useState(0);

    const [modalView, setModalView] = useState<KpiModalViews>(KpiModalViews.INITIAL);
    const [kpiModalDetails, setKpiModalDetails] = useState<Omit<KpiModalDetails, 'isAreaCap'>>({
      isModalOpen: false,
      kpiType: 'dailyConversionRate',
      operation: KpiEditTypes.ADD,
    });

    const {
      result: updatedKpiTargetProps,
      isLoading: updateTargetLoading,
      triggerAction: triggerUpdateTarget,
    } = useAsyncAction<KpiTargetsProps>();

    // Fabulous feature flag
    const { enabled: rfidEnabled } = useDecide('Rfid_widgets', {
      customAttributes: {
        locale: currentStoreId.slice(0, 2),
      },
    });

    // Update Target
    const updateTarget = async (updateRequest: UpdateKpiRequest[]) => {
      const response = await updateKpiData(updateRequest);

      if (isFailureResponse(response)) {
        setModalView(KpiModalViews.ERROR);
        throw new Error();
      }
      const { data } = response;
      setModalView(KpiModalViews.SUCCESS);
      return data;
    };

    const handleDropdownDetails = (kpiType: KpiType, action: KpiDropdownActions) => {
      const operationType = action.includes('add') ? KpiEditTypes.ADD : KpiEditTypes.UPDATE;
      setKpiModalDetails({
        isModalOpen: true,
        kpiType,
        operation: operationType,
      });
    };

    const triggerFetchKPITargets = async () => {
      const date = format(selectedDate as Date, 'yyyy-MM-dd');
      const response = await fetchKpiTargets(date);

      if (isFailureResponse(response)) {
        return null;
      }

      set({ kpiTargetsProps: response.data });
    };

    // Update the kpiTargetsProps state when updatedKpiTargetProps changes
    useEffect(() => {
      if (updatedKpiTargetProps) {
        set({ kpiTargetsProps: updatedKpiTargetProps });
      }
    }, [updatedKpiTargetProps]);

    useEffect(() => {
      if (interval === StorePerformanceInterval.WEEKLY) return; // TODO: Remove this once backend implements this
      triggerFetchKPITargets();
    }, [selectedDate, interval]);

    useEffect(() => {
      const containerEl = containerRef.current;

      if (!containerEl) return;

      const widgetCardEls = containerEl.querySelectorAll('[data-widget-card-order]');

      if (widgetCardEls.length === 0) return;

      let highestOrder = 0;

      widgetCardEls.forEach((el) => {
        const order = el.getAttribute('data-widget-card-order');

        if (order === null || isNaN(parseInt(order))) return;

        const elOrder = parseInt(order);

        if (elOrder > highestOrder) highestOrder = elOrder;
      });

      setHighestWidgetOrder(highestOrder);
    });

    const hasPermissionToEditKpi = userPermissions.kpiTargets.canUpdate;
    const hasTransactionSearchPageReadPermission = userPermissions.transactionSearchPage.canRead;

    const { getWidgetOrder } = createGetWidgetOrder(selectedCategory, highlightedKpiTargets);
    const { getIsWidgetVisible } = createGetIsWidgetVisible({
      userPermissions,
      selectedCategory,
      highlightedKpiTargets,
      getIsWidgetDisabled: () => false, // for now all widgets will be enabled for all markets
    });

    const { weekStart, weekEnd } = getWeekBounds(selectedDate);

    return (
      <Container ref={containerRef} data-testid="widgets-container">
        <KpiModal
          isOpen={kpiModalDetails.isModalOpen}
          onKpiModalClose={() => {
            setKpiModalDetails({
              ...kpiModalDetails,
              isModalOpen: false,
            });
            setModalView(KpiModalViews.INITIAL);
          }}
          kpiType={kpiModalDetails.kpiType}
          widgetName={KpiToApiMapping[kpiModalDetails.kpiType].widgetName}
          updateType={kpiModalDetails.operation}
          modalView={modalView}
          onKpiModalRetry={() => setModalView(KpiModalViews.INITIAL)}
          isModalLoading={updateTargetLoading}
          handleUpdateKpi={(updateRequest) => {
            triggerUpdateTarget(() => updateTarget(updateRequest));
          }}
        />
        {/* New Members Widget */}
        {getIsWidgetVisible(WidgetType.NewMembers) && (
          <WidgetCard
            name={WidgetType.NewMembers}
            col={1}
            order={getWidgetOrder(WidgetType.NewMembers)}
            highestOrder={highestWidgetOrder}
          >
            <NewMembersWidget
              interval={interval}
              currentDate={selectedDate}
              hasPermissionToEditKpi={hasPermissionToEditKpi}
              isToday={isToday}
              handleDropdownDetails={handleDropdownDetails}
              fromDate={weekStart}
              toDate={weekEnd}
            />
          </WidgetCard>
        )}
        {/* ConversionRate Widget */}
        {getIsWidgetVisible(WidgetType.ConversionRate) && (
          <WidgetCard
            name={WidgetType.ConversionRate}
            col={2}
            order={getWidgetOrder(WidgetType.ConversionRate)}
            highestOrder={highestWidgetOrder}
          >
            <ConversionRateWidget
              interval={interval}
              currentDate={selectedDate}
              hasPermissionToEditKpi={hasPermissionToEditKpi}
              isNonSigninUser={isNonSigninUser}
              isToday={isToday}
              handleDropdownDetails={handleDropdownDetails}
              fromDate={weekStart}
              toDate={weekEnd}
            />
          </WidgetCard>
        )}
        {/* HourlyTurnover Widget */}
        {getIsWidgetVisible(WidgetType.HourlyTurnover) && (
          <WidgetCard
            name={WidgetType.HourlyTurnover}
            col={2}
            order={getWidgetOrder(WidgetType.HourlyTurnover)}
            highestOrder={highestWidgetOrder}
          >
            <HourlyTurnoverWidget
              interval={interval}
              currentDate={selectedDate}
              hasPermissionToEditKpi={hasPermissionToEditKpi}
              isToday={isToday}
              handleDropdownDetails={handleDropdownDetails}
              fromDate={weekStart}
              toDate={weekEnd}
            />
          </WidgetCard>
        )}

        {/* SCO HourlyTurnover Widget */}
        {getIsWidgetVisible(WidgetType.SCOHourlyTurnover) && (
          <WidgetCard
            name={WidgetType.SCOHourlyTurnover}
            col={2}
            order={getWidgetOrder(WidgetType.SCOHourlyTurnover)}
            highestOrder={highestWidgetOrder}
          >
            <SCOHourlyTurnoverWidget
              interval={interval}
              currentDate={selectedDate}
              hasPermissionToEditKpi={hasPermissionToEditKpi}
              isToday={isToday}
              handleDropdownDetails={handleDropdownDetails}
              fromDate={weekStart}
              toDate={weekEnd}
            />
          </WidgetCard>
        )}
        {/* Top Selling Garment Widget */}
        {getIsWidgetVisible(WidgetType.TopSellingGarment) && (
          <WidgetCard
            name={WidgetType.TopSellingGarment}
            col={1.5}
            order={getWidgetOrder(WidgetType.TopSellingGarment)}
            highestOrder={highestWidgetOrder}
          >
            <TopSellingGarmentWidget
              currentDate={selectedDate}
              isNonSigninUser={isNonSigninUser}
              interval={interval}
              fromDate={weekStart}
              toDate={weekEnd}
            />
          </WidgetCard>
        )}
        {/* Top Returning Garment Widget */}
        {getIsWidgetVisible(WidgetType.TopReturningGarment) && (
          <WidgetCard
            name={WidgetType.TopReturningGarment}
            col={1.5}
            order={getWidgetOrder(WidgetType.TopReturningGarment)}
            highestOrder={highestWidgetOrder}
          >
            <TopReturningGarmentWidget
              currentDate={selectedDate}
              interval={interval}
              fromDate={weekStart}
              toDate={weekEnd}
            />
          </WidgetCard>
        )}
        {/* ShareOfReceipts Widget */}
        {getIsWidgetVisible(WidgetType.ShareOfReceipts) && (
          <WidgetCard
            name={WidgetType.ShareOfReceipts}
            col={1.5}
            order={getWidgetOrder(WidgetType.ShareOfReceipts)}
            highestOrder={highestWidgetOrder}
          >
            <ShareOfReceipts
              interval={interval}
              currentDate={selectedDate}
              hasPermissionToEditKpi={hasPermissionToEditKpi}
              transactionEventsCount={transactionEventsCount}
              isToday={isToday}
              handleDropdownDetails={handleDropdownDetails}
              fromDate={weekStart}
              toDate={weekEnd}
            />
          </WidgetCard>
        )}
        {/* ShareOfReceipts Widget */}
        {getIsWidgetVisible(WidgetType.SCOShareOfReceipts) && (
          <WidgetCard
            name={WidgetType.SCOShareOfReceipts}
            col={1.5}
            order={getWidgetOrder(WidgetType.SCOShareOfReceipts)}
            highestOrder={highestWidgetOrder}
          >
            <SCOShareOfReceipts
              interval={interval}
              currentDate={selectedDate}
              hasPermissionToEditKpi={hasPermissionToEditKpi}
              transactionEventsCount={transactionEventsCount}
              isToday={isToday}
              handleDropdownDetails={handleDropdownDetails}
              fromDate={weekStart}
              toDate={weekEnd}
            />
          </WidgetCard>
        )}
        {/* App Customers Widget */}
        {/* TODO: Remove this flag while integrating the new app customers widget */}
        {showAppCustomersWidget && getIsWidgetVisible(WidgetType.AppCustomersWidget) && (
          <WidgetCard
            name={WidgetType.AppCustomersWidget}
            col={1.5}
            order={getWidgetOrder(WidgetType.AppCustomersWidget)}
            highestOrder={highestWidgetOrder}
          >
            <AppCustomers
              isToday={isToday}
              interval={interval}
              currentDate={selectedDate}
              fromDate={weekStart}
              toDate={weekEnd}
            />
          </WidgetCard>
        )}

        {/* Pieces Per Receipt Widget */}
        {getIsWidgetVisible(WidgetType.PiecesPerReceipt) && (
          <WidgetCard
            name={WidgetType.PiecesPerReceipt}
            col={1.5}
            order={getWidgetOrder(WidgetType.PiecesPerReceipt)}
            highestOrder={highestWidgetOrder}
          >
            <PiecesPerReceiptWidget
              interval={interval}
              currentDate={selectedDate}
              isToday={isToday}
              fromDate={weekStart}
              toDate={weekEnd}
            />
          </WidgetCard>
        )}
        {/* Average Purchase Widget */}
        {getIsWidgetVisible(WidgetType.AveragePurchaseWidget) && (
          <WidgetCard
            name={WidgetType.AveragePurchaseWidget}
            col={1.5}
            order={getWidgetOrder(WidgetType.AveragePurchaseWidget)}
            highestOrder={highestWidgetOrder}
          >
            <AveragePurchaseWidget
              interval={interval}
              currentDate={selectedDate}
              isToday={isToday}
              fromDate={weekStart}
              toDate={weekEnd}
            />
          </WidgetCard>
        )}
        {/* Area Comparison Widget */}
        {getIsWidgetVisible(WidgetType.AreaComparisonWidget) && showAreaWidget && (
          <WidgetCard
            name={WidgetType.AreaComparisonWidget}
            col={2}
            order={getWidgetOrder(WidgetType.AreaComparisonWidget)}
            highestOrder={highestWidgetOrder}
          >
            <AreaComparisonWidget
              interval={interval}
              currentDate={selectedDate}
              fromDate={weekStart}
              toDate={weekEnd}
            />
          </WidgetCard>
        )}
        {/* Tender Widget */}
        {getIsWidgetVisible(WidgetType.TenderOverview) && (
          <WidgetCard
            name={WidgetType.TenderOverview}
            col={1.5}
            order={getWidgetOrder(WidgetType.TenderOverview)}
            highestOrder={highestWidgetOrder}
          >
            <TenderOverviewWidget currentDate={selectedDate} interval={interval} />
          </WidgetCard>
        )}
        {/* Registered receipts Widget */}
        {getIsWidgetVisible(WidgetType.RegisteredReceipts) && (
          <WidgetCard
            name={WidgetType.RegisteredReceipts}
            col={1}
            order={getWidgetOrder(WidgetType.RegisteredReceipts)}
            highestOrder={highestWidgetOrder}
          >
            <RegisteredReceiptsWidget
              isNonSigninUser={isNonSigninUser}
              hasTransactionSearchPageReadPermission={hasTransactionSearchPageReadPermission}
              currentDate={selectedDate}
              transactionEventsCount={transactionEventsCount}
              interval={interval}
            />
          </WidgetCard>
        )}
        {/* Asset Management Widget */}
        {getIsWidgetVisible(WidgetType.AssetManagement) && (
          <WidgetCard
            name={WidgetType.AssetManagement}
            col={2}
            order={getWidgetOrder(WidgetType.AssetManagement)}
            highestOrder={highestWidgetOrder}
          >
            <AssetManagementWidget currentDate={selectedDate} interval={interval} />
          </WidgetCard>
        )}
        {/* Queue Length Widget */}
        {getIsWidgetVisible(WidgetType.QueueLengthWidget) && (
          <WidgetCard
            name={WidgetType.QueueLengthWidget}
            col={1.5}
            order={getWidgetOrder(WidgetType.QueueLengthWidget)}
            highestOrder={highestWidgetOrder}
          >
            <QueueLengthWidget
              interval={interval}
              currentDate={selectedDate}
              isToday={isToday}
              hasPermissionToEditKpi={hasPermissionToEditKpi}
              fromDate={weekStart}
              toDate={weekEnd}
              handleDropdownDetails={handleDropdownDetails}
            />
          </WidgetCard>
        )}
        {/* Customer Feedback Widget */}
        {getIsWidgetVisible(WidgetType.CustomerFeedBackWidget) && (
          <WidgetCard
            name={WidgetType.CustomerFeedBackWidget}
            col={1}
            order={getWidgetOrder(WidgetType.CustomerFeedBackWidget)}
            highestOrder={highestWidgetOrder}
          >
            <CustomerFeedbackWidget
              interval={interval}
              currentDate={selectedDate}
              fromDate={weekStart}
              toDate={weekEnd}
            />
          </WidgetCard>
        )}

        {/* RFID Approvals Widget */}
        {getIsWidgetVisible(WidgetType.RfidApprovalsWidget) && rfidEnabled && (
          <WidgetCard
            name={WidgetType.RfidApprovalsWidget}
            col={2}
            order={getWidgetOrder(WidgetType.RfidApprovalsWidget)}
            highestOrder={highestWidgetOrder}
          >
            <RfidApprovalsWidget
              interval={interval}
              currentDate={selectedDate}
              fromDate={weekStart}
              toDate={weekEnd}
            />
          </WidgetCard>
        )}

        {/* RFID Count Widget */}
        {getIsWidgetVisible(WidgetType.RfidCountWidget) && (
          <WidgetCard
            name={WidgetType.RfidCountWidget}
            col={1.5}
            order={getWidgetOrder(WidgetType.RfidCountWidget)}
            highestOrder={highestWidgetOrder}
          >
            <RfidCountWidget
              interval={interval}
              currentDate={selectedDate}
              fromDate={weekStart}
              toDate={weekEnd}
            />
          </WidgetCard>
        )}

        {/* RFID Sales and Retagging */}
        {getIsWidgetVisible(WidgetType.RfidSalesRetagging) && (
          <WidgetCard
            name={WidgetType.RfidSalesRetagging}
            col={1}
            order={getWidgetOrder(WidgetType.RfidSalesRetagging)}
            highestOrder={highestWidgetOrder}
          >
            <RfidSalesRetaggingWidget
              interval={interval}
              currentDate={selectedDate}
              fromDate={weekStart}
              toDate={weekEnd}
              hasPermissionToEditKpi={hasPermissionToEditKpi}
            />
          </WidgetCard>
        )}

        {/* ORIS Widget */}
        {getIsWidgetVisible(WidgetType.OrisCustomersWidget) && (
          <WidgetCard
            col={1.5}
            order={getWidgetOrder(WidgetType.OrisCustomersWidget)}
            name={WidgetType.OrisCustomersWidget}
            highestOrder={highestWidgetOrder}
          >
            <OrisCustomersWidget
              interval={interval}
              currentDate={selectedDate}
              fromDate={weekStart}
              toDate={weekEnd}
            />
          </WidgetCard>
        )}

        {/* Efficiency Widget */}
        {getIsWidgetVisible(WidgetType.EfficiencyWidget) && (
          <WidgetCard
            name={WidgetType.EfficiencyWidget}
            col={2}
            order={getWidgetOrder(WidgetType.EfficiencyWidget)}
            highestOrder={highestWidgetOrder}
          >
            <EfficiencyWidget
              isToday={isToday}
              interval={interval}
              currentDate={selectedDate}
              fromDate={weekStart}
              toDate={weekEnd}
            />
          </WidgetCard>
        )}
      </Container>
    );
  },
);
