import { useCallback, useEffect, useState } from 'react';

type Parameter = {
  name: string;
  stringValue: string;
};

type EventPayload = {
  eventName: string;
  eventType: string;
  parameters: Parameter[];
  env: string;
  sId: string;
  source: string;
  brandId?: number;
};

const generateSessionId = (): string => {
  const randomArray = new Uint8Array(16); // 16 bytes = 128 bits of randomness
  window.crypto.getRandomValues(randomArray);

  const randomString = Array.from(randomArray, (byte) => byte.toString(16).padStart(2, '0')).join('');
  const timestamp = Date.now();
  return `${randomString}:${timestamp}`;
};

const getApiUrl = (): string => {
  const env = process.env.REACT_APP_ENV;
  switch (env) {
    case 'production':
      return 'https://dep.hmgroup.com/v1/internal';
    case 'test':
      return 'https://collector-llmguu4m5a-ew.a.run.app/v1/internal';
    default:
      return 'https://collector-n3keh4osvq-ew.a.run.app/v1/internal';
  }
};

const useEventTracker = () => {
  const API_URL = getApiUrl();
  const [sessionId, setSessionId] = useState<string>('');

  useEffect(() => {
    const initializeSessionId = () => {
      let sId = sessionStorage.getItem('sessionId');
      if (!sId) {
        sId = generateSessionId();
        sessionStorage.setItem('sessionId', sId);
      }
      setSessionId(sId);
    };

    initializeSessionId();
  }, []);

  const trackEvent = useCallback(
    async (eventName: string, eventType: string, parameters: Parameter[]): Promise<void> => {
      if (!sessionId) return;

      const payload: EventPayload = {
        eventName,
        eventType,
        parameters,
        env: process.env.REACT_APP_ENV || 'development',
        sId: sessionId,
        source: 'box',
      };

      try {
        const success = navigator.sendBeacon(API_URL, JSON.stringify(payload));
        if (!success) {
          throw new Error('sendBeacon failed to send the request.');
        }
      } catch (error) {
        console.error('Error tracking event:', (error as Error).message);
      }
    },
    [API_URL, sessionId],
  );

  return trackEvent;
};

export default useEventTracker;
