import styled from 'styled-components';

export const StyledFlexItems = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexWrapper = styled(StyledFlexItems)`
  gap: ${({ theme }) => theme.spacing(5)};
  flex-wrap: wrap;
`;

export const SubHeaderWrapper = styled(StyledFlexItems)`
  justify-content: space-between;
  margin: ${({ theme }) => theme.spacing(2, 8)};
`;

export const StyledAnimationWrapper = styled.div`
  margin-top: 20vh;
`;

export const TableWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => theme.spacing(0, 8)};
  min-height: 0;
`;

export const HeaderWrapper = styled(StyledFlexItems)`
  flex-direction: row;
  gap: ${({ theme: { spacing } }) => spacing(5)};
  padding: ${({ theme }) => theme.spacing(0, 8)};
`;
