import { CircularProgress, useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BudgetPlanned, TrendEnum } from '../../../../constants/budgetPlanned';
import { useThemeContext } from '../../../../global-state/themeContext';
import { BudgetPlannedData } from '../../../../hooks';
import { Container, Icon, Icons, Typography } from '../../../../stories/atoms';
import { Animation, Animations } from '../../../../stories/molecules';
import { muiTheme } from '../../../../theme';
import { homeTranslations } from '../../../../translations';
import { UserPermissions } from '../../../../utils';
import { computeBudgetPlannedData } from '../../../../utils/budgetPlanned';
import {
  ItemContainer,
  SectionContentContainer,
  SectionHeaderContainer,
  StyledDivider,
  StyledSectionContainer,
  StyledTrendIcon,
  StyledTypography,
} from './BudgetPlannedContent.styles';

export const getBudgetAndPlannedPermissions = (userPermissions: UserPermissions) => {
  const canViewBudget = userPermissions.financialBudget.canRead;
  const canViewPlanned = userPermissions.financialPlanned.canRead;

  return {
    canViewBudget,
    canViewPlanned,
    showBudgetAndPlanned: canViewBudget || canViewPlanned,
  };
};

const { budgetPlanned: budgetPlannedTranslations, common } = homeTranslations;
type BudgetPlannedTranslation = keyof typeof budgetPlannedTranslations;

type CommonItemContainerProps = {
  label: string;
  value: number | string;
  trend: TrendEnum;
};

const CommonItemContainer: FC<CommonItemContainerProps> = ({ label, value, trend }) => {
  const { t } = useTranslation();
  const isMobileView = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));
  return (
    <ItemContainer>
      <Typography color="secondaryTextGray">
        {t(
          budgetPlannedTranslations[label as BudgetPlannedTranslation].key,
          budgetPlannedTranslations[label as BudgetPlannedTranslation].defaultValue,
        )}
      </Typography>
      <StyledTypography trend={trend} isMobileView={isMobileView}>
        {value}
      </StyledTypography>
    </ItemContainer>
  );
};

type SectionContentProps = {
  label: string;
  icon: Icons;
  sectionData: BudgetPlanned;
};

const SectionContent: FC<SectionContentProps> = ({ label, icon, sectionData }) => {
  const { t } = useTranslation();
  const { mode } = useThemeContext();
  const { trend, data, percentage, index, differnce } = sectionData;
  return (
    <>
      <Container
        data-testid={`${label}-header`}
        style={{ position: 'relative' }}
        wrap="nowrap"
        position="center"
        space="between"
        direction="horizontal"
      >
        <StyledTrendIcon trend={trend} data-testid="trend-icon" icon={Icons.ARROW} />
        <SectionHeaderContainer>
          <Icon
            color={mode === 'dark' ? 'white' : 'textGray'}
            margin={[0, 2, 0, 0]}
            data-testid={`${label}-icon`}
            icon={icon}
          />
          <Typography type="button">
            {t(
              budgetPlannedTranslations[label as BudgetPlannedTranslation].key,
              budgetPlannedTranslations[label as BudgetPlannedTranslation].defaultValue,
            )}
          </Typography>
        </SectionHeaderContainer>
        <Typography>{parseFloat(data.toFixed(2)).toLocaleString()}</Typography>
      </Container>
      <SectionContentContainer data-testid={`${label}-content`}>
        <CommonItemContainer label="differenceText" value={differnce.toFixed(2)} trend={trend} />
        <CommonItemContainer label="indexText" value={index.toFixed()} trend={trend} />
        <CommonItemContainer
          label="trendText"
          value={`${trend === TrendEnum.UP ? '+' : '-'}${Math.abs(percentage).toFixed(2)}% ${
            label === 'bugdet'
              ? t(common.thanBudget.key, common.thanBudget.defaultValue)
              : t(common.thanPlanned.key, common.thanPlanned.defaultValue)
          }`}
          trend={trend}
        />
      </SectionContentContainer>
    </>
  );
};

export type BudgetPlannedContentProps = {
  title: string;
  currentData: number | null;
  budgetPlanned: BudgetPlannedData | null;
  canViewBudget: boolean;
  canViewPlanned: boolean;
  budgetNoData?: boolean;
  plannedNoData?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  isEffectiveHours?: boolean;
};

export const BudgetPlannedContent: FC<React.PropsWithChildren<BudgetPlannedContentProps>> = ({
  title,
  currentData,
  budgetPlanned,
  canViewBudget,
  canViewPlanned,
  budgetNoData,
  plannedNoData,
  isLoading = false,
  isError = false,
  isEffectiveHours = false,
}) => {
  const { t } = useTranslation();

  const {
    todayValue,
    budgetValue,
    plannedValue,
    budgetDiff,
    plannedDiff,
    budgetPercentage,
    plannedPercentage,
    budgetIndexValue,
    plannedIndexValue,
    budgetTrend,
    plannedTrend,
  } = computeBudgetPlannedData(currentData, budgetPlanned, isEffectiveHours);

  const budget = {
    trend: budgetTrend,
    data: budgetValue,
    percentage: budgetPercentage,
    index: budgetIndexValue,
    differnce: budgetDiff,
  };

  const planned = {
    trend: plannedTrend,
    data: plannedValue,
    percentage: plannedPercentage,
    index: plannedIndexValue,
    differnce: plannedDiff,
  };

  return (
    <>
      <Container margin={[0, 0, 2]}>
        <Typography type="h4">
          {t(budgetPlannedTranslations.title.key, budgetPlannedTranslations.title.defaultValue)}
        </Typography>
        <Typography>{title}</Typography>
      </Container>

      <StyledDivider data-testid="divider" />

      {isLoading && (
        <Container data-testid="loading-view" position="center" height="80%">
          <CircularProgress size={24} thickness={3} />
        </Container>
      )}

      {!isLoading && isError && (
        <Container data-testid="error-view" position="center" height="80%">
          <Animation
            title=""
            animation={Animations.SAD}
            size="small"
            errorText={t(budgetPlannedTranslations.error.key, budgetPlannedTranslations.error.defaultValue)}
          />
        </Container>
      )}

      {!isLoading && !isError && (
        <>
          <Typography>
            {t(budgetPlannedTranslations.currentText.key, budgetPlannedTranslations.currentText.defaultValue)}
          </Typography>
          <Typography type="button">{parseFloat(todayValue.toFixed(2)).toLocaleString()}</Typography>
        </>
      )}

      {!isLoading && !isError && canViewBudget && (
        <StyledSectionContainer data-testid="budget-wrapper" wrap="nowrap" padding={[2, 3]} margin={[2, 0, 3]}>
          {!budgetNoData && <SectionContent label="budget" icon={Icons.SALESEFFICIENCY} sectionData={budget} />}
          {budgetNoData && (
            <Container data-testid="budget-no-data" position="center" height="100px">
              <Typography type="button" textAlign="center">
                {t(
                  budgetPlannedTranslations.noBudgetDataText.key,
                  budgetPlannedTranslations.noBudgetDataText.defaultValue,
                )}
              </Typography>
            </Container>
          )}
        </StyledSectionContainer>
      )}

      {!isLoading && !isError && canViewPlanned && (
        <StyledSectionContainer data-testid="planned-wrapper" wrap="nowrap" padding={[2, 3]}>
          {!plannedNoData && <SectionContent label="planned" icon={Icons.LIST} sectionData={planned} />}
          {plannedNoData && (
            <Container data-testid="planned-no-data" position="center" height="100px">
              <Typography type="button" textAlign="center">
                {t(
                  budgetPlannedTranslations.noPlannedDataText.key,
                  budgetPlannedTranslations.noPlannedDataText.defaultValue,
                )}
              </Typography>
            </Container>
          )}
        </StyledSectionContainer>
      )}
    </>
  );
};
