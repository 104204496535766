import styled from 'styled-components';
import { Container } from '../../../../../stories/atoms';
import { cardShadow } from '../../../../../theme';

export const StyledContainer = styled(Container)`
  align-items: center;
  border-radius: ${({ theme: { spacing } }) => spacing(2)};
  ${cardShadow()};
  flex: 1;
`;

export const StyledSection = styled.div`
  display: flex;
  align-items: stretch;
  padding: ${({ theme: { spacing } }) => spacing(0, 5)};
  gap: ${({ theme: { spacing } }) => spacing(2)};
`;
