export const sideMenuTranslations = {
  general: {
    key: 'sideMenu.general',
    defaultValue: 'General',
  },
  customerCentricInsights: {
    key: 'sideMenu.customerCentricInsights',
    defaultValue: 'Customer Centric Insights',
  },
  dashboards: {
    key: 'sideMenu.dashboards',
    defaultValue: 'Dashboards',
  },
  home: {
    key: 'sideMenu.home',
    defaultValue: 'Home',
  },
  avatarManagement: {
    key: 'sideMenu.avatarManagement',
    defaultValue: 'Avatar Management',
  },
  transaction: {
    key: 'sideMenu.transaction',
    defaultValue: 'Transaction Search',
  },
  salesAnalysis: {
    key: 'sideMenu.salesAnalysis',
    defaultValue: 'Sales Analysis',
  },
  employees: {
    key: 'sideMenu.employees',
    defaultValue: 'Employees & Roles',
  },
  test: {
    key: 'sideMenu.test',
    defaultValue: 'Test',
  },
  development: {
    key: 'sideMenu.development',
    defaultValue: 'Development',
  },
  createAvatar: {
    key: 'sideMenu.createAvatar',
    defaultValue: 'Create avatar',
  },
  kpiTarget: {
    key: 'sideMenu.kpiTarget',
    defaultValue: 'KPI Targets',
  },
  soldProducts: {
    key: 'sideMenu.soldProducts',
    defaultValue: 'Sold Products',
  },
  productCategory: {
    key: 'sideMenu.productCategory',
    defaultValue: 'Product Category',
  },
  profitAndLoss: {
    key: 'sideMenu.profitAndLoss',
    defaultValue: 'Profit And Loss',
  },
  widgetAccessControl: {
    key: 'sideMenu.widgetAccessControl',
    defaultValue: 'Widget Access Control',
  },
};

export const headerTranslations = {
  messages: {
    key: 'header.messages',
    defaultValue: 'Messages',
  },
  messageWithCount: {
    key: 'header.messageWithCount',
    defaultValue: 'Messages ({{count}})',
  },
  signout: {
    key: 'header.signout',
    defaultValue: 'Sign out',
  },
  signin: {
    key: 'header.signin',
    defaultValue: 'Sign in',
  },
};
