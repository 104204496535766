import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '../../../../stories/atoms';
import { Translation } from '../../../../utils';
import { StyledTrendIconComponent } from '../styledTrendIcon/StyledTrendIcon';
import { StyledTrendContainer } from './TrendInfo.styles';

type TrendInfoProps = {
  isMobile: boolean;
  timeFrame: 'lastWeek' | 'lastYear';
  today: number;
  previousAmount: number;
  valueFormatter: (value: number) => string;
  hasPercentage?: boolean;
  common: {
    [key: string]: Translation;
  };
};

export const TrendInfo: FC<TrendInfoProps> = ({
  isMobile,
  timeFrame,
  today,
  previousAmount,
  valueFormatter,
  hasPercentage,
  common,
}) => {
  const { t } = useTranslation();
  return (
    <StyledTrendContainer data-testid="trend-info-wrapper" isLeftElement={timeFrame === 'lastWeek'}>
      <Typography type="caption" margin={[0, 1, 0, 0]}>
        {isMobile
          ? timeFrame === 'lastWeek'
            ? 'LW'
            : 'LY'
          : t(common[timeFrame].key, common[timeFrame].defaultValue)}
        {' -'}
      </Typography>
      <StyledTrendIconComponent
        originalAmount={today}
        previousAmount={previousAmount}
        direction="horizontal"
        valueFormatter={valueFormatter}
        textOptions={{ type: 'caption' }}
        hasPercentage={hasPercentage}
      />
    </StyledTrendContainer>
  );
};
