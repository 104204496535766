import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StorePerformanceInterval, createStorePerformanceRequest } from '../../../../../constants';
import { useEventContext } from '../../../../../global-state/eventContext';
import { AppCustomersPerformanceItem, useStorePerformance, useTransaction } from '../../../../../hooks';
import { Container, Icon, Icons, Typography } from '../../../../../stories/atoms';
import { homeTranslations } from '../../../../../translations';
import { formatAmountWithSeparator, isFailureResponse } from '../../../../../utils';
import { WidgetCardContent } from '../../../common/widgetCardContent/WidgetCardContent';
import WidgetChart from '../../../common/widgetChart/WidgetChart';
import { StyledSection } from './AppCustomers.styles';

export type AppCustomersProps = {
  interval: StorePerformanceInterval;
  currentDate: Date;
  isToday: boolean;
  fromDate?: Date;
  toDate?: Date;
};

const AppCustomers: FC<AppCustomersProps> = observer(({ interval, currentDate, isToday, fromDate, toDate }) => {
  const { t } = useTranslation();
  const { appCustomers: appCustomersTranslations } = homeTranslations;

  const { fetchAppCustomersData } = useTransaction();
  const { transactionEventsCount } = useEventContext();

  const [appCustomers, setAppCustomers] = useState<string>('');

  const {
    performanceItems,
    performanceItemsLoading,
    performanceItemsError,

    filteredPerformanceItems,

    forecastPerformanceItems,
    triggerGetStorePerformance,
  } = useStorePerformance<AppCustomersPerformanceItem>();

  const fetchData = useCallback(async () => {
    // Fetch the request based on interval
    const request = createStorePerformanceRequest({
      interval,
      ...(interval === StorePerformanceInterval.DAILY ? { currentDate } : { fromDate, toDate }),
    });

    const response = await fetchAppCustomersData(request);

    if (isFailureResponse(response)) {
      throw new Error();
    }

    setAppCustomers(response.data.appCustomers);

    return response.data.newAppCustomers;
  }, [interval, currentDate]);

  const totalAppCustomersCount = () => {
    return performanceItems.reduce((acc, val) => acc + (val ? val.newCustomerCount : 0), 0);
  };

  const itemValueResolver = (item: AppCustomersPerformanceItem) => {
    return item.newCustomerCount;
  };

  const isNoData = !filteredPerformanceItems.some((item) => item !== null);

  const fetchWidgetData = () => {
    triggerGetStorePerformance({
      interval: interval,
      currentDate: currentDate,
      isToday: isToday,
      getDataAction: fetchData,
      getHasValue: (item) => item.newCustomerCount !== 0,
    });
  };

  useEffect(() => {
    fetchWidgetData();
  }, [interval, isToday, currentDate, transactionEventsCount]);

  return (
    <WidgetCardContent
      headerIcon={Icons.CUSTOMERVALUE}
      headerText={t(appCustomersTranslations.title.key, appCustomersTranslations.title.defaultValue)}
      subHeaderText={t(appCustomersTranslations.subTitle.key, appCustomersTranslations.subTitle.defaultValue)}
      toolTipText={t(appCustomersTranslations.toolTip.key, appCustomersTranslations.toolTip.defaultValue)}
      isLoading={performanceItemsLoading}
      isError={performanceItemsError}
      errorText={t(appCustomersTranslations.error.key, appCustomersTranslations.error.defaultValue)}
      errorRetryHandler={fetchWidgetData}
      isNoData={isNoData}
      content={
        <>
          <Container wrap="nowrap" padding={[3, 4]} data-testid="app-customers-wrapper" height="100%">
            <Typography>
              {t(
                appCustomersTranslations.newCustomersText.key,
                appCustomersTranslations.newCustomersText.defaultValue,
              )}
            </Typography>
            <Typography type="h4">{totalAppCustomersCount()}</Typography>
            <Container wrap="nowrap" data-testid="chart" style={{ flex: 1 }}>
              <WidgetChart
                type="area"
                performanceItemsChartName={t(
                  appCustomersTranslations.newCustomersText.key,
                  appCustomersTranslations.newCustomersText.defaultValue,
                )}
                performanceItems={performanceItems}
                filteredPerformanceItems={filteredPerformanceItems}
                forecastPerformanceItems={forecastPerformanceItems}
                itemValueResolver={itemValueResolver}
                showXAxis={true}
                showYAxis={false}
                tooltipFormatter={(value) => formatAmountWithSeparator(value)}
              />
            </Container>
            <StyledSection
              padding={[3, 2]}
              wrap="nowrap"
              space="between"
              direction="horizontal"
              data-testid="app-customers"
            >
              <Container wrap="nowrap" direction="horizontal" position="center">
                <Icon data-testid="mobileIcon" icon={Icons.MOBILE} margin={[0, 1, 0, 0]} />
                <Typography>
                  {t(appCustomersTranslations.title.key, appCustomersTranslations.title.defaultValue)}
                </Typography>
              </Container>

              <Typography type="button">{`${appCustomers} %`}</Typography>
            </StyledSection>
          </Container>
        </>
      }
    />
  );
});

export default AppCustomers;
