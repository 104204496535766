import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { baseUrlCDN } from '../../../constants';
import { WeatherResponse } from '../../../hooks';
import { Typography } from '../../../stories/atoms';
import { Animation, Animations } from '../../../stories/molecules';

import { StyledIconWrapper, StyledTemperature, StyledWeatherContainer, WeatherIcon } from './WeatherWidget.styles';

export type WeatherWidgetProps = {
  data: WeatherResponse;
  isError?: boolean;
};

export const WeatherWidget: FC<WeatherWidgetProps> = ({ data, isError }) => {
  const { t } = useTranslation();

  const getWeatherIcons = (weatherCondition: string) =>
    `${baseUrlCDN}/weather/${weatherCondition}.svg${process.env.REACT_APP_CDN_TOKEN}`;

  const { name, temperature, temperatureHigh, temperatureLow, icon, situation, units } = data;

  const ErrorAnimation = () => {
    return (
      <StyledWeatherContainer data-testid="error-weather-widget">
        <Animation title="" animation={Animations.SAD} size="small" />
      </StyledWeatherContainer>
    );
  };

  if (isError) return <ErrorAnimation />;

  return (
    <StyledWeatherContainer data-testid="weather-widget">
      <div>
        <Typography>{name}</Typography>
        <Typography type="h1">
          {Math.round(temperature) === 0 ? 0 : Math.round(temperature)}
          {units === 'Celsius' ? '\u00B0C' : '\u00B0F'}
        </Typography>
        <StyledTemperature data-testid="temperature-wrapper">
          <Typography padding={[0, 1, 0]}>
            H:
            {Math.round(temperatureHigh) === 0 ? 0 : Math.round(temperatureHigh)}
            {units === 'Celsius' ? '\u00B0C' : '\u00B0F'}
          </Typography>
          <Typography>
            L:
            {Math.round(temperatureLow) === 0 ? 0 : Math.round(temperatureLow)}
            {units === 'Celsius' ? '\u00B0C' : '\u00B0 F'}
          </Typography>
        </StyledTemperature>
      </div>
      <StyledIconWrapper>
        <WeatherIcon src={getWeatherIcons(icon)} alt={icon} />
        <Typography textAlign="center">{t(`home.weather.${situation}`, situation)}</Typography>
      </StyledIconWrapper>
    </StyledWeatherContainer>
  );
};
