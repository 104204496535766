import { List, useMediaQuery } from '@mui/material';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { RouteList } from '../../../App';
import { corporateBrandIcons } from '../../../constants/brandLogos';
import { useThemeContext } from '../../../global-state/themeContext';
import { Profile } from '../../../global-state/types';
import { useUser } from '../../../hooks';
import useEventTracker from '../../../hooks/useEventTracker';
import { muiTheme } from '../../../theme';
import { employeesTranslations } from '../../../translations';
import { sideMenuTranslations } from '../../../translations/navigation';
import { RoleMapping, isSupportedSuamRole } from '../../../utils';
import { Drawer, Icon, Icons, Typography } from '../../atoms';
import { Badge } from '../../atoms/badge/Badge';
import { IconWithText } from '../../molecules';
import {
  StyledContainer,
  StyledHeader,
  StyledIcon,
  StyledLinkWrapper,
  StyledNavLink,
  StyledProfileDetails,
  StyledProfileImage,
  StyledSection,
  StyledTypography,
} from './SideMenu.styles';

export type HeaderProps = {
  profile: Profile;
  storeId: string;
};

export type SideMenuProps = {
  header: HeaderProps;
  list: RouteList[];
  isOpen?: boolean;
  onClose?: () => void;
};

export const SideMenu: FC<React.PropsWithChildren<SideMenuProps>> = ({ header, list, isOpen, onClose }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const {
    get: { currentStoreId, stores },
  } = useUser();

  const navigate = useNavigate();
  const { mode } = useThemeContext();
  const isDesktop = useMediaQuery(muiTheme.breakpoints.up('desktopLarge'));
  const trackEvent = useEventTracker();

  const {
    profile: { avatar, firstName, lastName, assignments, jobTitles },
    storeId,
  } = header;

  const { createAvatar } = sideMenuTranslations;
  const { other } = employeesTranslations.employees;

  // Get the corporateBrandId from the current store details
  const corporateId = stores.find(
    ({ storeId: selectedStoreId }) => selectedStoreId === currentStoreId,
  )?.corporateBrandId;
  const brandIcon = corporateId ? corporateBrandIcons[corporateId] : Icons.HM_LOGO;

  const renderContent = (
    <>
      <StyledIcon data-testid="homeIcon" onClick={() => navigate(`/home`)} icon={brandIcon} margin={[3, 0, 0]} />
      <StyledHeader data-testid="drawer-header">
        {avatar?.url ? (
          <StyledProfileImage
            src={`${avatar.url}${process.env.REACT_APP_CDN_TOKEN}`}
            onClick={() => navigate(`/avatar-management`)}
            alt="profile-avatar"
          />
        ) : (
          <Icon
            data-testid="no-profile-icon"
            icon={Icons.PROFILE}
            size="large"
            margin={[0, 1, 0, 0]}
            onClick={() => navigate(`/avatar-management`)}
          />
        )}
        <StyledProfileDetails>
          <StyledTypography data-testid="fullname" type="body3">{`${firstName} ${lastName}`}</StyledTypography>

          <StyledTypography data-testid="store-roles" color="textGray">
            {jobTitles
              ? jobTitles.find(({ storeId: selectedStore }) => selectedStore === storeId)?.jobTitles?.join(', ')
              : assignments // For now this will be a fallbackOption until all profiles are updated with jobTitles
                  ?.filter(({ locationCode }) => locationCode === storeId)
                  .map(({ suamRoleCode }) =>
                    isSupportedSuamRole(suamRoleCode)
                      ? t(`employees.roles.${RoleMapping[suamRoleCode]}`, RoleMapping[suamRoleCode])
                      : t(other.key, other.defaultValue),
                  )
                  .join(', ')}
          </StyledTypography>

          {!avatar?.url && (
            <Link data-testid="no-account-link" to={`/avatar-management`}>
              <Typography data-testid="create-avatar" margin={[1, 0, 0, 0]}>
                {t(createAvatar.key, createAvatar.defaultValue)}
              </Typography>
            </Link>
          )}
        </StyledProfileDetails>
      </StyledHeader>

      <StyledSection>
        {list.map(
          ({ section: { key, defaultValue }, links }) =>
            links.length > 0 && (
              <div key={key}>
                <Typography
                  data-testid="section-header"
                  type="body3"
                  padding={[0, 7]}
                  color={mode === 'dark' ? 'white' : 'textGray'}
                  margin={[2, 0, 0]}
                >
                  {t(key, defaultValue)}
                </Typography>
                <List sx={{ paddingTop: 3, paddingBottom: 3 }}>
                  {links.map(({ translation, path, icon, badgeIcon }) => (
                    <StyledNavLink
                      key={translation.key}
                      data-testid="page-link"
                      to={path === '/employees/:storeId' ? `/employees/${currentStoreId}` : path}
                      onClick={() => {
                        trackEvent('menu_navigation', 'page', [
                          { name: 'menu_item', stringValue: translation.defaultValue || translation.key },
                          { name: 'menu_path', stringValue: path },
                          { name: 'menu_type', stringValue: isDesktop ? 'permanent' : 'temporary' },
                        ]);
                      }}
                    >
                      <StyledLinkWrapper isVisited={matchPath(path, pathname)?.pattern.path === path}>
                        <IconWithText
                          data-testid={path}
                          iconOptions={{ icon: icon }}
                          textOptions={{
                            type: 'body2',
                          }}
                          containerStyles={{ width: '100%', justifyContent: 'flex-start' }}
                          spaceBetween="large"
                          padding={[3, 7]}
                          label={t(translation.key, translation?.defaultValue || translation.key)}
                          hoverColor={'red'}
                        />
                        {badgeIcon && (
                          <div>
                            <Badge backgroundColor="red">{badgeIcon}</Badge>
                          </div>
                        )}
                      </StyledLinkWrapper>
                    </StyledNavLink>
                  ))}
                </List>
              </div>
            ),
        )}
      </StyledSection>
    </>
  );

  useEffect(() => {
    if (isOpen && onClose) {
      onClose();
    }
  }, [pathname]);

  return (
    <StyledContainer data-testid="side-menu">
      {isDesktop ? (
        <Drawer>{renderContent}</Drawer>
      ) : (
        <Drawer
          variant="temporary"
          isOpen={isOpen}
          onClose={onClose}
          ModalProps={{ keepMounted: true }}
          drawerWidth={muiTheme.spacing(80)}
        >
          {renderContent}
        </Drawer>
      )}
    </StyledContainer>
  );
};
