import styled from 'styled-components';
import { Container, Icon } from '../../atoms';

export const StyledWrapper = styled(Container)`
  position: relative;
  overflow: hidden;
`;

export const BackDropContainer = styled.div<{ isSliderOpen: boolean }>`
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.textGray : color.primary};
  opacity: ${({ isSliderOpen }) => (isSliderOpen ? 0.5 : 0)};
  width: 100%;
  position: absolute;
  z-index: 6;
  height: 100%;
  display: ${({ isSliderOpen }) => (isSliderOpen ? '' : 'none')};
  transition: opacity 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
`;

export const OverlayContainer = styled.div<{ isSliderOpen: boolean; width: string }>`
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'light' ? color.white : color.backgroundPaperDark};
  width: ${({ width }) => width && width};
  position: absolute;
  z-index: 7;
  right: ${({ theme: { spacing } }) => spacing(0)};
  height: 100%;
  transform: ${({ isSliderOpen }) => (isSliderOpen ? 'translateX(0%)' : 'translateX(100%)')};
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  padding: ${({ theme: { spacing } }) => spacing(3, 3, 2)};
`;

export const StyledIcon = styled(Icon)`
  position: absolute;
  right: ${({ theme: { spacing } }) => spacing(0)};
  top: ${({ theme: { spacing } }) => spacing(0)};
`;
