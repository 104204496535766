import { useThemeContext } from '../../../../global-state/themeContext';
import { Icons } from '../../../atoms';
import { IconWithText } from '../../icon-with-text/IconWithText';

type Props = {
  title: string;
  onClick: () => void;
};

export const FilterTag = ({ title, onClick }: Props) => {
  const { mode } = useThemeContext();

  return (
    <IconWithText
      data-testid="filter-tag"
      label={title}
      iconOptions={{
        icon: Icons.CLOSE,
        margin: [0, 2],
        size: 'small',
        color: mode === 'dark' ? 'primary' : 'white',
        onClick: onClick,
      }}
      textOptions={{ whiteSpace: 'nowrap', color: mode === 'dark' ? 'primary' : 'white' }}
      backgroundColor={mode === 'dark' ? 'white' : 'primary'}
      labelPosition="left"
      padding={[2, 0, 2, 2]}
    />
  );
};
