import styled, { css } from 'styled-components';
import { Container } from '../../stories/atoms';

export const StyledScreenContainer = styled(Container)`
  height: 100vh;
  background-color: ${({ theme: { color, palette } }) => palette.mode === 'dark' && color.backgroundDark};
`;

const wrapper = css`
  display: flex;
  height: 100%;
  width: 100%;
  padding: ${({ theme: { spacing } }) => spacing(5, 0)};
`;

export const StyledWrapper = styled.div`
  flex-direction: column;
  ${wrapper};
`;

export const StyledContainer = styled.div`
  flex-direction: row;
  ${wrapper};
`;

export const StyledLogoContainer = styled(Container)<{ isMobile?: boolean }>`
  padding: ${({ theme: { spacing }, isMobile }) => !isMobile && spacing(6)};
`;

export const StyledMapContainer = styled.div<{ isMobile?: boolean }>`
  height: 80vh;
  width: ${({ isMobile }) => (isMobile ? '100vw' : '68vw')};
  padding: ${({ theme: { spacing } }) => spacing(6)};
`;

export const StyledSearchContainer = styled.div`
  flex: 1;
  padding: ${({ theme: { spacing } }) => spacing(4, 6)};
`;

export const StyledStoreDetails = styled.div<{ isMobile?: boolean }>`
  margin-top: ${({ theme: { spacing }, isMobile }) => !isMobile && spacing(10)};
  padding: ${({ theme: { spacing }, isMobile }) => isMobile && spacing(0, 6)};
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'row' : 'column')};
  align-items: ${({ isMobile }) => isMobile && 'center'};
  ${({ isMobile }) => isMobile && `justify-content: space-between;`}/* Apply space-between only for mobile */
`;
