import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AreaComparisonItem } from '../../../../../../hooks';
import { Container, Typography } from '../../../../../../stories/atoms';
import { homeTranslations } from '../../../../../../translations';
import { formatAmountWithCurrency } from '../../../../../../utils';
import { calculateColor, calculateIndexValue, calculateTrend } from '../../../../../../utils/budgetPlanned';
import { MetricsTranslationMapping } from '../AreaComparison';
import { StyledSection } from '../AreaComparison.styles';

export type AreaComparisonCardProps = {
  areaComparisonDetail: AreaComparisonItem;
  view: string;
};

const AreaComparisonCard: FC<AreaComparisonCardProps> = ({ areaComparisonDetail, view }) => {
  const { t, i18n } = useTranslation();
  const { name, today, lastWeek, lastYear, lastWeekArea, lastYearArea } = areaComparisonDetail;
  const { common, areaComparison: areaComparisonTranslations } = homeTranslations;

  const isEffectiveHours = name === 'averageEffectiveHours';

  const getIndexValue = (comparedValue: number) => {
    return calculateIndexValue(today, comparedValue);
  };

  const getColor = (comparedValue: number) => {
    const trend = calculateTrend(today ?? 0, comparedValue, isEffectiveHours);

    return calculateColor(trend);
  };

  return (
    <StyledSection key={areaComparisonDetail.name} data-testid="area-comparison-card-wrapper">
      <Typography color="textGray" textAlign="start" type="caption" padding={[0, 1, 2]}>
        {t(
          areaComparisonTranslations.equationGroup[
            MetricsTranslationMapping[areaComparisonDetail.name].equationGroup
          ].key,
          areaComparisonTranslations.equationGroup[
            MetricsTranslationMapping[areaComparisonDetail.name].equationGroup
          ].defaultValue,
        )}
      </Typography>

      <Container direction="horizontal">
        <Typography type="button" padding={[0, 1, 0]}>
          {t(
            MetricsTranslationMapping[areaComparisonDetail.name].translation.key,
            MetricsTranslationMapping[areaComparisonDetail.name].translation.defaultValue,
          )}
        </Typography>
        <Typography type="button">
          -{' '}
          {formatAmountWithCurrency(
            areaComparisonDetail.today ? areaComparisonDetail.today : 0,
            i18n.language,
            '',
          )}
        </Typography>
      </Container>

      <Typography textAlign="end" padding={[0, 2]}>
        {t(areaComparisonTranslations.title.key, areaComparisonTranslations.title.defaultValue)}
      </Typography>

      <Container direction="horizontal" wrap="nowrap" padding={[1]} space="between">
        <Typography color="textGray">{t(common.lastWeek.key, common.lastWeek.defaultValue)}</Typography>
        <Typography color={getColor(areaComparisonDetail.lastWeek)}>
          {view === 'view2'
            ? getIndexValue(lastWeek)
            : formatAmountWithCurrency(areaComparisonDetail.lastWeek, i18n.language, '')}
        </Typography>
        <Typography color={getColor(areaComparisonDetail.lastWeekArea)}>
          {view === 'view2'
            ? getIndexValue(lastWeekArea)
            : formatAmountWithCurrency(areaComparisonDetail.lastWeekArea, i18n.language, '')}
        </Typography>
      </Container>

      <Container direction="horizontal" wrap="nowrap" padding={[0, 1]} space="between">
        <Typography color="textGray">{t(common.lastYear.key, common.lastYear.defaultValue)}</Typography>
        <Typography color={getColor(areaComparisonDetail.lastYear)}>
          {view === 'view2'
            ? getIndexValue(lastYear)
            : formatAmountWithCurrency(areaComparisonDetail.lastYear, i18n.language, '')}
        </Typography>
        <Typography color={getColor(areaComparisonDetail.lastYearArea)}>
          {view === 'view2'
            ? getIndexValue(lastYearArea)
            : formatAmountWithCurrency(areaComparisonDetail.lastYearArea, i18n.language, '')}
        </Typography>
      </Container>
    </StyledSection>
  );
};

export default AreaComparisonCard;
