import { Divider } from '@mui/material';
import styled from 'styled-components';
import { TrendEnum } from '../../../../constants/budgetPlanned';
import { Container, Icon, Typography } from '../../../../stories/atoms';
import { Color, cardShadow } from '../../../../theme';

export const FlexRow = styled.div`
  display: flex;
`;

export const FlexColumn = styled(FlexRow)`
  flex-direction: column;
`;

const getColor = (mode: string, trend: TrendEnum | null, color: Color) => {
  if (mode === 'dark') {
    return color.white;
  }

  switch (trend) {
    case TrendEnum.UP:
      return color.validationGreen;
    case TrendEnum.DOWN:
      return color.red;
    default:
      return color.primary;
  }
};

export const StyledDivider = styled(Divider)`
  margin-bottom: ${({ theme: { spacing } }) => spacing(2)};
  background-color: ${({ theme: { color, palette } }) => palette.mode === 'dark' && color.borderGrayDark};
`;

export const StyledSectionContainer = styled(Container)`
  border-radius: ${({ theme: { spacing } }) => spacing(2)};
  ${cardShadow()};
`;

export const StyledIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const SectionHeaderContainer = styled(FlexRow)`
  align-items: center;
`;

export const SectionContentContainer = styled(FlexColumn)`
  margin: ${({ theme: { spacing } }) => spacing(3, 0, 0)};
  gap: ${({ theme: { spacing } }) => spacing(1)};
`;

export const ItemContainer = styled(FlexRow)`
  justify-content: space-between;
`;

export const StyledTrendIcon = styled(Icon)<{ trend: TrendEnum }>`
  position: absolute;
  top: ${({ theme: { spacing } }) => `-${spacing(5)}`};
  right: ${({ theme: { spacing } }) => `-${spacing(4)}`};
  transform: ${({ trend }) => (trend === TrendEnum.UP ? 'rotate(-45deg)' : 'rotate(45deg)')};
  background-color: ${({ theme: { color, palette }, trend }) =>
    trend === TrendEnum.UP
      ? color.successLight
      : palette.mode === 'light'
      ? color.errorLight
      : color.backgroundMenuActive};
  color: ${({ theme: { color }, trend }) => (trend === TrendEnum.UP ? color.validationGreen : color.red)};
  padding: ${({ theme: { spacing } }) => spacing(1)};
  border-radius: ${({ theme: { spacing } }) => spacing(3)};
`;

export const StyledTypography = styled(Typography)<{ trend: TrendEnum; isMobileView: boolean }>`
  color: ${({ theme: { color, palette }, trend }) => getColor(palette.mode, trend, color)};
  width: ${({ theme: { spacing }, isMobileView }) => (isMobileView ? spacing(30) : spacing(44))};
  text-align: right;
`;
