import styled from 'styled-components';

export const StyledResponseHeader = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  justify-content: space-between;
  border-top: 1px solid
    ${({ theme: { color, palette } }) => (palette.mode === 'dark' ? color.borderGrayDark : color.borderGray)};
  padding: ${({ theme, isMobile }) => (isMobile ? theme.spacing(3, 0) : theme.spacing(1))};
  gap: ${({ theme }) => theme.spacing(3)};
`;

export const StyledChipGroup = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  > div {
    margin: ${({ theme }) => theme.spacing(1)};
    :nth-child(2) {
      display: flex;
      flex-direction: row;
      gap: ${({ theme }) => theme.spacing(2)};
    }
  }
`;

export const StyledFeedbackText = styled.div`
  display: flex;
  border: 1px solid
    ${({ theme: { color, palette } }) => (palette.mode === 'dark' ? color.borderGrayDark : color.borderGray)};
  margin: ${({ theme }) => theme.spacing(1)};
  border-radius: ${({ theme }) => theme.spacing(2)};
`;
