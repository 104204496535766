import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Forecast,
  KpiDropdownActions,
  KpiType,
  StorePerformanceInterval,
  createStorePerformanceRequest,
} from '../../../../../constants';
import {
  ShareOfReceiptsItem,
  useKpiTargetDistriubution,
  useStorePerformance,
  useStores,
  useTransaction,
  useUser,
} from '../../../../../hooks';
import { Container, Icon, Icons, Typography } from '../../../../../stories/atoms';
import { DropdownMenu } from '../../../../../stories/molecules';
import { homeTranslations } from '../../../../../translations';
import { getKpiTargetProps, getKpiTargetValue, isFailureResponse } from '../../../../../utils';
import { StyledCustomLegends } from '../../../Home.styles';
import { ProgressIndicator } from '../../../common/progressIndicator/ProgressIndicator';
import { WidgetCardContent } from '../../../common/widgetCardContent/WidgetCardContent';
import WidgetChart from '../../../common/widgetChart/WidgetChart';
import { StyledRightContainerWrapper } from '../../salesAndEfficiency/conversionRate/ConversionRateWidget.styles';
import {
  StyledBreakdownContainer,
  StyledLegendContainer,
  StyledSubHeader,
  StyledView,
} from './ShareOfReceipts.styles';

export type ShareOfReceiptsProps = {
  interval: StorePerformanceInterval;
  currentDate: Date;
  hasPermissionToEditKpi: boolean;
  transactionEventsCount: number;
  isToday: boolean;
  handleDropdownDetails: (kpiType: KpiType, action: KpiDropdownActions) => void;
  fromDate?: Date;
  toDate?: Date;
};

export const ShareOfReceipts: FC<ShareOfReceiptsProps> = observer(
  ({
    interval,
    currentDate,
    hasPermissionToEditKpi,
    transactionEventsCount,
    isToday,
    handleDropdownDetails,
    fromDate,
    toDate,
  }) => {
    const {
      get: { currentStoreId, kpiTargetsProps },
    } = useUser();

    const { getKpiTargetDistriubution } = useKpiTargetDistriubution();
    const { fetchShareOfReceipts } = useTransaction();
    const { fetchForecastForKpis } = useStores();

    const [lastUpdated, setLastUpdated] = useState<Date | null>(null);

    const {
      performanceItems,
      performanceItemsLoading,
      performanceItemsError,

      filteredPerformanceItems,

      forecastPerformanceItems,
      triggerGetStorePerformance,
    } = useStorePerformance<ShareOfReceiptsItem>();

    const isTargetLocked = getKpiTargetProps(kpiTargetsProps, 'dailyShareOfReceipts')?.isLocked ?? false;

    const { t } = useTranslation();
    const target = getKpiTargetValue(kpiTargetsProps, 'dailyShareOfReceipts');

    const { shareOfReceipts, kpiTargets } = homeTranslations;

    const itemValueResolver = (item: ShareOfReceiptsItem) => {
      const { memberTransactions, totalTransactions } = item;
      return totalTransactions === 0 ? 0 : parseInt(((memberTransactions / totalTransactions) * 100).toFixed(2));
    };

    const fetchData = useCallback(async () => {
      // Fetch the request based on interval
      const request = createStorePerformanceRequest({
        interval,
        ...(interval === StorePerformanceInterval.DAILY ? { currentDate } : { fromDate, toDate }),
      });

      const response = await fetchShareOfReceipts(request);

      if (isFailureResponse(response)) {
        throw new Error();
      }

      setLastUpdated(response.data.lastUpdated);

      return response.data.shareOfReceipts;
    }, [interval, currentDate]);

    const fetchForecastData = async () => {
      const response = await fetchForecastForKpis<ShareOfReceiptsItem>('shareOfReceipt');

      if (isFailureResponse(response)) {
        throw new Error();
      }

      return response.data;
    };

    const getShareOfReceipts = (items: (ShareOfReceiptsItem | null)[]) => {
      let currentTotalMemberTransactions = 0;
      let currentTotalTransactions = 0;

      items.forEach((item) => {
        if (item === null) return;

        const { memberTransactions, totalTransactions } = item;

        if (totalTransactions === 0) return;

        currentTotalMemberTransactions += memberTransactions;
        currentTotalTransactions += totalTransactions;
      });

      const currentShareOfReceipts =
        currentTotalTransactions === 0
          ? 0
          : parseFloat(((currentTotalMemberTransactions / currentTotalTransactions) * 100).toFixed(2));

      return {
        currentShareOfReceipts: currentShareOfReceipts,
        currentTotalMemberTransactions: currentTotalMemberTransactions,
      };
    };

    const fetchWidgetData = () => {
      triggerGetStorePerformance({
        interval: interval,
        currentDate: currentDate,
        isToday: isToday,
        getDataAction: fetchData,
        getForecastDataAction: fetchForecastData,
        getHasValue: (item) => item.totalTransactions !== 0,
      });
    };

    useEffect(() => {
      fetchWidgetData();
    }, [interval, currentDate, isToday, transactionEventsCount]);

    const targetItems = getKpiTargetDistriubution({
      performanceItems: performanceItems,
      kpiName: 'dailyShareOfReceipts',
    });

    const isNoData = !filteredPerformanceItems.some((item) => item !== null);

    const showForecast = !!target && forecastPerformanceItems.some((item) => item !== null);

    const isEditKPIDisabled = performanceItemsError || !hasPermissionToEditKpi;

    const { currentShareOfReceipts, currentTotalMemberTransactions } =
      getShareOfReceipts(filteredPerformanceItems);

    const { currentShareOfReceipts: forecastShareOfReceipts } = getShareOfReceipts([
      ...filteredPerformanceItems,
      ...forecastPerformanceItems,
    ]);

    const targetProgressPercent = target && target != 0 ? (forecastShareOfReceipts / target) * 100 : 0;

    const menuItems = [
      {
        key: target ? 'editkpiForSOR' : 'addkpiForSOR',
        disabled: isEditKPIDisabled || isTargetLocked || interval === StorePerformanceInterval.WEEKLY,
        hasTooltip: isEditKPIDisabled || isTargetLocked,
        tooltipText: (
          <Container padding={[1]}>
            <Typography color="white">
              {!hasPermissionToEditKpi
                ? t(kpiTargets.noPermissionTooltip.key, kpiTargets.noPermissionTooltip.defaultValue)
                : isTargetLocked
                ? t(kpiTargets.lockedTargetTooltip.key, kpiTargets.lockedTargetTooltip.defaultValue)
                : t(kpiTargets.disabledTooltip.key, kpiTargets.disabledTooltip.defaultValue)}
            </Typography>
          </Container>
        ),
        item: (
          <Typography margin={[0, 2, 0]}>
            {target
              ? t(kpiTargets.editkpiForSOR.key, kpiTargets.editkpiForSOR.defaultValue)
              : t(kpiTargets.addkpiForSOR.key, kpiTargets.addkpiForSOR.defaultValue)}
          </Typography>
        ),
      },
    ];

    return (
      <WidgetCardContent
        headerIcon={Icons.CUSTOMERVALUE}
        headerText={t(shareOfReceipts.title.key, shareOfReceipts.title.defaultValue)}
        subHeaderText={
          t(shareOfReceipts.subTitle.key, shareOfReceipts.subTitle.defaultValue) + ' ' + currentStoreId
        }
        lastUpdatedDate={lastUpdated}
        isLoading={performanceItemsLoading}
        isError={performanceItemsError}
        errorText={t(shareOfReceipts.error.key, shareOfReceipts.error.defaultValue)}
        errorRetryHandler={fetchWidgetData}
        isNoData={isNoData}
        headerSideContent={
          <StyledRightContainerWrapper>
            <DropdownMenu
              data-testid="kpiDropdown"
              dropdownLabel={''}
              buttonContentProps={{
                iconOptions: { customIcon: <Icon icon={Icons.MORE} /> },
              }}
              menuItems={menuItems}
              minWidth={'0px'}
              isCompact
              initialSelected={[]}
              onSelect={(selectedItem) => {
                if (!isEditKPIDisabled) {
                  handleDropdownDetails('dailyShareOfReceipts', selectedItem[0] as KpiDropdownActions);
                }
              }}
              isOnlyIcon
              allowTooltip
            />
          </StyledRightContainerWrapper>
        }
        content={
          <>
            <Container wrap="nowrap" data-testid="share-of-receipts-wrapper" height="100%">
              <StyledView>
                {showForecast && (
                  <ProgressIndicator
                    valueType={Forecast.PERCENTAGE}
                    value={forecastShareOfReceipts}
                    progressPercentage={targetProgressPercent}
                    padding={[1, 2]}
                    margin={[2, 2, 1, 2]}
                  />
                )}
                <StyledSubHeader>
                  <StyledBreakdownContainer>
                    <Typography>{`${t(
                      homeTranslations.shareOfReceipts.totalShareOfReceipts.key,
                      homeTranslations.shareOfReceipts.totalShareOfReceipts.defaultValue,
                    )}: ${currentShareOfReceipts}%`}</Typography>
                    <Typography>{`${t(
                      homeTranslations.shareOfReceipts.totalMemberTransactions.key,
                      homeTranslations.shareOfReceipts.totalMemberTransactions.defaultValue,
                    )}: ${currentTotalMemberTransactions.toFixed()}`}</Typography>
                  </StyledBreakdownContainer>

                  <StyledLegendContainer>
                    {target && (
                      <StyledCustomLegends legendColor="tagBlue" type="legal" margin={[0]}>
                        {`${t(
                          homeTranslations.common.targetKPISOR.key,
                          homeTranslations.common.targetKPISOR.defaultValue,
                        )} ${target}%`}
                      </StyledCustomLegends>
                    )}
                  </StyledLegendContainer>
                </StyledSubHeader>
                <Container wrap="nowrap" data-testid="chart" style={{ flex: 1, overflow: 'hidden' }}>
                  <WidgetChart
                    performanceItemsChartName={t(
                      homeTranslations.shareOfReceipts.sor.key,
                      homeTranslations.shareOfReceipts.sor.defaultValue,
                    )}
                    performanceItems={performanceItems}
                    filteredPerformanceItems={filteredPerformanceItems}
                    forecastPerformanceItems={forecastPerformanceItems}
                    targetItems={targetItems}
                    itemValueResolver={itemValueResolver}
                    showXAxis={true}
                    yAxisFormatter={(value) => `${value}%`}
                    tooltipFormatter={(value) => `${Math.ceil(value)}`}
                  />
                </Container>
              </StyledView>
            </Container>
          </>
        }
        toolTipText={t(shareOfReceipts.toolTip.key, shareOfReceipts.toolTip.defaultValue)}
      />
    );
  },
);
