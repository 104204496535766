import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Forecast,
  KpiDropdownActions,
  KpiType,
  StorePerformanceInterval,
  createStorePerformanceRequest,
} from '../../../../../constants';
import { useEventContext } from '../../../../../global-state/eventContext';
import {
  SCOHourlyTurnoverPerformanceItem,
  useKpiTargetDistriubution,
  useStoreInformation,
  useStorePerformance,
  useStores,
  useTransaction,
  useUser,
} from '../../../../../hooks';
import { Container, Icon, Icons, Typography } from '../../../../../stories/atoms';
import { DropdownMenu } from '../../../../../stories/molecules';
import { muiTheme } from '../../../../../theme';
import { homeTranslations } from '../../../../../translations';
import { formatValue, getKpiTargetProps, getKpiTargetValue, isFailureResponse } from '../../../../../utils';
import { StyledCustomLegends } from '../../../Home.styles';
import { ProgressIndicator } from '../../../common/progressIndicator/ProgressIndicator';
import { WidgetCardContent } from '../../../common/widgetCardContent/WidgetCardContent';
import WidgetChart from '../../../common/widgetChart/WidgetChart';
import { StyledView, StyledWrapper } from '../hourlyTurnover/HourlyTurnoverWidget.styles';

type SCOHourlyTurnoverWidgetProps = {
  interval: StorePerformanceInterval;
  currentDate: Date;
  hasPermissionToEditKpi: boolean;
  isToday: boolean;
  handleDropdownDetails: (kpiType: KpiType, action: KpiDropdownActions) => void;
  fromDate?: Date;
  toDate?: Date;
};

export const SCOHourlyTurnoverWidget: FC<SCOHourlyTurnoverWidgetProps> = observer(
  ({ interval, currentDate, hasPermissionToEditKpi, isToday, handleDropdownDetails, fromDate, toDate }) => {
    const {
      get: { kpiTargetsProps },
    } = useUser();

    const { getKpiTargetDistriubution } = useKpiTargetDistriubution();
    const { currencyCode } = useStoreInformation();
    const { fetchSCOHourlyTurnoverWidgetData } = useTransaction();
    const { fetchForecastForKpis } = useStores();
    const { transactionEventsCount } = useEventContext();
    const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));

    const [lastUpdated, setLastUpdated] = useState<Date | null>(null);

    const {
      performanceItems,
      performanceItemsLoading,
      performanceItemsError,

      filteredPerformanceItems,

      forecastPerformanceItems,
      triggerGetStorePerformance,
    } = useStorePerformance<SCOHourlyTurnoverPerformanceItem>();

    const target = getKpiTargetValue(kpiTargetsProps, 'dailySCOTurnOver');
    const isTargetLocked = getKpiTargetProps(kpiTargetsProps, 'dailySCOTurnOver')?.isLocked ?? false;

    const { t, i18n } = useTranslation();
    const {
      scoHourlyTurnover: scoHourlyTurnoverTranslations,
      common: commonTranslations,
      kpiTargets,
    } = homeTranslations;

    const itemValueResolver = (item: SCOHourlyTurnoverPerformanceItem) => {
      return parseFloat(item.netSaleSCO.toFixed(2));
    };

    const fetchData = useCallback(async () => {
      // Fetch the request based on interval
      const request = createStorePerformanceRequest({
        interval,
        ...(interval === StorePerformanceInterval.DAILY ? { currentDate } : { fromDate, toDate }),
      });
      const response = await fetchSCOHourlyTurnoverWidgetData(request);

      if (isFailureResponse(response)) {
        throw new Error();
      }

      setLastUpdated(response.data.lastUpdated);

      return response.data.netSalesSCO;
    }, [interval, currentDate]);

    const fetchForecastData = useCallback(async () => {
      const response = await fetchForecastForKpis<SCOHourlyTurnoverPerformanceItem>('netSaleSCO');

      if (isFailureResponse(response)) {
        throw new Error();
      }
      return response.data;
    }, [isToday]);

    const totalSCONetSales = performanceItems.reduce((acc, val) => {
      return acc + val.netSaleSCO;
    }, 0);

    const totalNetSales = performanceItems.reduce((acc, val) => {
      return acc + val.netSale;
    }, 0);

    const SCOShare = totalNetSales === 0 ? 0 : (totalSCONetSales / totalNetSales) * 100;

    const fetchWidgetData = () => {
      triggerGetStorePerformance({
        interval: interval,
        currentDate: currentDate,
        isToday: isToday,
        getDataAction: fetchData,
        getHasValue: (item) => item.netSale !== 0,
        getForecastDataAction: fetchForecastData,
      });
    };

    useEffect(() => {
      fetchWidgetData();
    }, [interval, isToday, currentDate, transactionEventsCount]);

    const targetItems = getKpiTargetDistriubution({
      performanceItems: performanceItems,
      kpiName: 'dailySCOTurnOver',
    });

    const getTotalNetSales = (items: (SCOHourlyTurnoverPerformanceItem | null)[]) => {
      return items.reduce((acc, val) => acc + (val ? val.netSaleSCO : 0), 0);
    };

    const forecastTotalNetSales = getTotalNetSales([...performanceItems, ...forecastPerformanceItems]);
    const isEditKPIDisabled = !hasPermissionToEditKpi;

    const isNoData = totalSCONetSales === 0;
    const showForecast = !!target && forecastPerformanceItems.some((item) => item !== null);
    const targetProgressPercent = target && target != 0 ? (forecastTotalNetSales / target) * 100 : 0;

    const menuItems = [
      {
        key: target ? 'editkpi' : 'addkpi',
        disabled: isEditKPIDisabled || isTargetLocked || interval === StorePerformanceInterval.WEEKLY,
        hasTooltip: isEditKPIDisabled || isTargetLocked,
        tooltipText: (
          <Container padding={[1]}>
            <Typography color="white">
              {!hasPermissionToEditKpi
                ? t(kpiTargets.noPermissionTooltip.key, kpiTargets.noPermissionTooltip.defaultValue)
                : isTargetLocked
                ? t(kpiTargets.lockedTargetTooltip.key, kpiTargets.lockedTargetTooltip.defaultValue)
                : t(kpiTargets.disabledTooltip.key, kpiTargets.disabledTooltip.defaultValue)}
            </Typography>
          </Container>
        ),
        item: (
          <Typography margin={[0, 2, 0]}>
            {target
              ? t(kpiTargets.editKpi.key, kpiTargets.editKpi.defaultValue)
              : t(kpiTargets.addKpi.key, kpiTargets.addKpi.defaultValue)}
          </Typography>
        ),
      },
    ];

    return (
      <WidgetCardContent
        headerIcon={Icons.SALESEFFICIENCY}
        headerText={t(scoHourlyTurnoverTranslations.title.key, scoHourlyTurnoverTranslations.title.defaultValue)}
        subHeaderText={t(
          scoHourlyTurnoverTranslations.subTitle.key,
          scoHourlyTurnoverTranslations.subTitle.defaultValue,
        )}
        toolTipText={t(
          scoHourlyTurnoverTranslations.toolTip.key,
          scoHourlyTurnoverTranslations.toolTip.defaultValue,
        )}
        isLoading={performanceItemsLoading}
        isError={performanceItemsError}
        errorText={t(scoHourlyTurnoverTranslations.error.key, scoHourlyTurnoverTranslations.error.defaultValue)}
        errorRetryHandler={fetchWidgetData}
        isNoData={isNoData || false}
        noDataText={t(commonTranslations.noSCOReceipts.key, commonTranslations.noSCOReceipts.defaultValue)}
        lastUpdatedDate={lastUpdated}
        headerSideContent={
          <StyledWrapper>
            <DropdownMenu
              data-testid="kpiDropdown"
              dropdownLabel={''}
              buttonContentProps={{
                iconOptions: { customIcon: <Icon icon={Icons.MORE} /> },
              }}
              menuItems={menuItems}
              minWidth={'0px'}
              isCompact
              initialSelected={[]}
              onSelect={(selectedItems) => {
                handleDropdownDetails('dailySCOTurnOver', selectedItems[0] as KpiDropdownActions);
              }}
              isOnlyIcon
              allowTooltip
            />
          </StyledWrapper>
        }
        content={
          <Container wrap="nowrap" data-testid="sco-hourly-turnover-wrapper" height="100%">
            <StyledView isMobile={isMobile}>
              {showForecast && (
                <ProgressIndicator
                  valueType={Forecast.CURRENCY}
                  value={forecastTotalNetSales}
                  progressPercentage={targetProgressPercent}
                  padding={[1, 2]}
                  margin={[1, 2, 2]}
                />
              )}
              <Container
                style={{ gap: '8px' }}
                wrap="nowrap"
                direction="horizontal"
                space="between"
                width="100%"
                padding={[0, 3]}
              >
                <Container wrap="nowrap">
                  <Typography>
                    {t(
                      scoHourlyTurnoverTranslations.totalSales.key,
                      scoHourlyTurnoverTranslations.totalSales.defaultValue,
                    )}
                    :{' '}
                    {`${formatValue(totalSCONetSales, {
                      locale: i18n.language,
                    })} ${currencyCode}`}
                  </Typography>
                  <Typography>
                    {t(
                      scoHourlyTurnoverTranslations.scoShare.key,
                      scoHourlyTurnoverTranslations.scoShare.defaultValue,
                    )}
                    : {SCOShare.toFixed(2)}%
                  </Typography>
                </Container>

                {target && (
                  <StyledCustomLegends legendColor="tagBlue" type="legal" margin={[0]}>
                    {t(commonTranslations.targetKPIHourly.key, commonTranslations.targetKPIHourly.defaultValue)}{' '}
                    {target}
                  </StyledCustomLegends>
                )}
              </Container>
              <Container wrap="nowrap" data-testid="chart" style={{ flex: 1 }}>
                <WidgetChart
                  performanceItemsChartName={t(
                    scoHourlyTurnoverTranslations.subTitle.key,
                    scoHourlyTurnoverTranslations.subTitle.defaultValue,
                  )}
                  performanceItems={performanceItems}
                  filteredPerformanceItems={filteredPerformanceItems}
                  forecastPerformanceItems={forecastPerformanceItems}
                  targetItems={targetItems}
                  itemValueResolver={itemValueResolver}
                  showXAxis={true}
                  yAxisFormatter={(value) =>
                    `${Intl.NumberFormat(i18n.language, { notation: 'compact' }).format(value)}`
                  }
                  tooltipFormatter={(value) =>
                    `${formatValue(value, {
                      locale: i18n.language,
                    })} ${currencyCode}`
                  }
                />
              </Container>
            </StyledView>
          </Container>
        }
      />
    );
  },
);
