export enum TrendEnum {
  UP = 'Up',
  DOWN = 'Down',
}

export type BudgetPlanned = {
  trend: TrendEnum;
  data: number;
  percentage: number;
  index: number;
  differnce: number;
};
